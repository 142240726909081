import { Paper, Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import "rsuite/dist/rsuite-no-reset.min.css";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";
import { distinctFilter, findDataRange, getUniqueValuesFromJson, isNumeric } from "../utils/dataManipulation";

import "react-resizable/css/styles.css";
import { Table } from "../components";
import { isArray } from "lodash";
import { useSelector } from "react-redux";
import { useGetAllBuildings } from "../api/hooks/allHooks";
import { AssetCardModal } from "../components/AssetOverview/AssetCardModal";
import { DynamicDashboardMultiTab } from "../components/chart/DynamicDashboardMultiTab";
import { areItemsSame, categoricalColumns, columnsNamesEnums, columnsUnits, dataMapping, dataNeededColumns, defaultColors, FiltersNames, infoBoxAndTableNeededColumns, isRelevantOption } from "../enums/AssetOverview/portfolio";
import { FilterTypes } from "../enums/components";
import { chartTypesEnums, diagramTypes, getSortOptions, valuesEnums } from "../enums/dynamicDashboard";
import { handleConfigChange, settingsOptions } from "../utils/dynamicDashboard";

const filtersCategories = [
  {
    categoryName: 'Building profile',
    filtersNames: [
      columnsNamesEnums.PORTFOLIO,
      columnsNamesEnums.BUILDING_ID,
      columnsNamesEnums.BUILDING_NAME,
      columnsNamesEnums.USE_TYPE,
      columnsNamesEnums.BOUGHT_ON,
      columnsNamesEnums.SOLD_ON,
      columnsNamesEnums.RENTAL_UNITS,
      columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      columnsNamesEnums.FLOOR_AMOUMT,
      columnsNamesEnums.HERITAGE_PROTECTED,
      columnsNamesEnums.BUILDING_TYPE_ID,
      columnsNamesEnums.BUILDING_TYPE,
      columnsNamesEnums.PRE_FABRICATED,
      columnsNamesEnums.LOGGIA,
      columnsNamesEnums.BALCONY,
      columnsNamesEnums.FOOTPRINT,
      columnsNamesEnums.CEILING_AREA,
      columnsNamesEnums.FACADE_AREA,
      columnsNamesEnums.PREMISE,
      columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      columnsNamesEnums.NET_AREA_WITH_BASEMENT,
      columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      // columnsNamesEnums.ACCOUNTING_AREA,

    ],
  },
  {
    categoryName: 'Geography',
    filtersNames: [
      columnsNamesEnums.ZIP,
      columnsNamesEnums.CITY,
      columnsNamesEnums.MAIN_DISTRICT,
      columnsNamesEnums.SUB_DISTRICT,
      columnsNamesEnums.FULL_ADDRESS,
      columnsNamesEnums.STREET,
    ],
  },
  {
    categoryName: 'Energy & CO2',
    filtersNames: [
      columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      columnsNamesEnums.HEAT_ENERGY_TYPE,
      columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
      columnsNamesEnums.NATURAL_GAS_AVAILABLE,
      columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      columnsNamesEnums.CO2_EMISSION,
      columnsNamesEnums.STRANDING_YEAR,
    ],
  },
]

const defaultPortfolioConfigs = [
  {
    "tab": "Portfolio summary",
    "filters": {
      "Energy Efficiency class": [
        null,
        "C",
        "A",
        "B"
      ],
      "Gross area": [
        29000,
        51430
      ],
      "Energy consumption": [
        3.63,
        66
      ],
      "Heat energy consumption": [
        16.35,
        123
      ],
      "Electrictiy consumption": [
        48.95,
        54
      ]
    },
    "settings": [
      {
        "type": "1",
        "id": 1,
        "x": 7,
        "y": 5,
        "w": 5,
        "h": 6,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": "Footprint area",
            "diagramType": "scatter",
            "color": "#0adb53",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "composed",
        "title": "Footprint VS Premise",
        "xAxis": "Premise area",
        "yAxis": null,
        "dataPoint": null,
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 2,
        "x": 7,
        "y": 0,
        "w": 5,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": "CO2 Emissions",
            "diagramType": "scatter",
            "color": "#f91010",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "bar",
        "title": "CO2 Emissions per Usage",
        "xAxis": "Main usage",
        "yAxis": null,
        "dataPoint": "Gross area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 3,
        "x": 0,
        "y": 5,
        "w": 7,
        "h": 6,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "#f61313",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "heatmap",
        "title": "Gross area ",
        "xAxis": "Energy Efficiency class",
        "yAxis": "Main usage",
        "dataPoint": "Gross area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 4,
        "x": 4,
        "y": 0,
        "w": 3,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": "Main usage",
            "stacks": null,
            "percentageBased": false,
            "direction": "vertical",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "kpi",
        "title": "Footprint area",
        "xAxis": null,
        "yAxis": null,
        "dataPoint": "Footprint area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 5,
        "x": 0,
        "y": 0,
        "w": 4,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "miniMap",
        "title": "Heat Energy Consumption",
        "xAxis": null,
        "yAxis": null,
        "dataPoint": "Heat energy consumption",
        "legendsPosition": "bottom"
      }
    ],
    "selectedFiltersNames": []
  },
  {
    "filters": {
      "Main usage": [
        "Office"
      ]
    },
    "settings": [
      {
        "type": "1",
        "id": 0,
        "x": 0,
        "y": 0,
        "w": 4,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "bar",
        "title": "Energy Consumption",
        "xAxis": "Heat energy type",
        "yAxis": null,
        "dataPoint": "Energy consumption",
        "legendsPosition": "bottom"
      }
    ],
    "selectedFiltersNames": [
      "Main usage"
    ],
    "tab": "Office"
  }
]

const defaultPortfolioConfigsAndColorsConfigs = { colors: { ...defaultColors }, tabs: defaultPortfolioConfigs }

const loadDashboardConfigs = () => {
  let dashboardConfigs = localStorage.getItem("portfolioConfigs");
  if (dashboardConfigs) {
    dashboardConfigs = JSON.parse(dashboardConfigs);
  } else {
    dashboardConfigs = defaultPortfolioConfigsAndColorsConfigs;
  }
  return dashboardConfigs
};


export const PortfolioNew = ({ }) => {
  const { t } = useTranslation()

  const { user } = useSelector((store) => store.user);
  const { data, isLoading } = useGetAllBuildings(user?.user_id);

  const dataPreprocessing = useMemo(() => {
    const mappedData = []
    const mappedInfoBoxAndTableData = []
    data?.forEach((row) => {
      const mappedDataRow = {}
      const mappedInfoBoxAndTableRow = {}
      Object.keys(row).forEach((oldDataKey) => {
        let value = row[oldDataKey]

        const newDataKey = dataMapping[oldDataKey]
        if (categoricalColumns.includes(newDataKey)) {
          value = value ?? valuesEnums.NO_DATA
        }
        if (infoBoxAndTableNeededColumns.includes(newDataKey)) {
          mappedInfoBoxAndTableRow[newDataKey] = value
        }

        if (dataNeededColumns.includes(newDataKey)) {
          mappedDataRow[newDataKey] = value
        }
        mappedDataRow[columnsNamesEnums.COUNT] = 1
      })

      mappedInfoBoxAndTableData.push(mappedInfoBoxAndTableRow)
      mappedData.push(mappedDataRow)
    })

    return [mappedData, mappedInfoBoxAndTableData]

  }, [data])

  const [generalDashboardData, infoBoxesAndTableData] = useMemo(() => dataPreprocessing, [dataPreprocessing])

  let dataColumns = [],
    numericalColumns = [];
  if (generalDashboardData?.length) {
    dataColumns = Object.keys(generalDashboardData[0]);
    numericalColumns = dataColumns.filter(col => !categoricalColumns.includes(col))
  }

  const [filteredData, setFilteredData] = useState(generalDashboardData)

  const headers = [
    {
      label: "Name",
      dataKey: columnsNamesEnums.BUILDING_NAME,
      cellType: null,
      valueTransformation: (row) => <Stack gap={2}>
        <img className="w-12 h-12 object-contain object-center" src={row[columnsNamesEnums.IMAGE_URL_VALUE]} />
        <span>
          {row[columnsNamesEnums.BUILDING_NAME]}
        </span>
      </Stack>,
      subValueTransformation: (row) => null,
      valueClassName: 'py-1',
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Portfolio',
      dataKey: columnsNamesEnums.PORTFOLIO,
      cellType: "",
      valueTransformation: (row) => row[columnsNamesEnums.PORTFOLIO],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Heat energy type',
      dataKey: columnsNamesEnums.HEAT_ENERGY_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.HEAT_ENERGY_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: 'Main usage',
      dataKey: columnsNamesEnums.USE_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.USE_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Energy consumption',
      dataKey: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'CO2 Emissions',
      dataKey: columnsNamesEnums.CO2_EMISSION,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.CO2_EMISSION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
  ]

  const tableFilteredData = infoBoxesAndTableData.filter(tableDataRow => {
    return filteredData?.some(mainDataRow => mainDataRow[columnsNamesEnums.ID] === tableDataRow[columnsNamesEnums.ID])
  })


  // ADDEDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
  // ADDEDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
  // ADDEDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
  const [saveConfigRerenderTemp, setSaveConfigRerenderTemp] = useState(false);

  let savedPortfolioAndColorsConfigsMemoized = useMemo(() => {
    let savedPortfolioAndColorsConfigs = loadDashboardConfigs()
    if (isArray(savedPortfolioAndColorsConfigs)) {
      savedPortfolioAndColorsConfigs = { colors: {}, tabs: savedPortfolioAndColorsConfigs }
    }
    return savedPortfolioAndColorsConfigs
  }, [saveConfigRerenderTemp])


  const dataColumnsOptionsGroups = filtersCategories?.map(category => {
    const sortedOptions = [...category.filtersNames]

    sortedOptions.sort()
    return { group: category.categoryName, options: sortedOptions }
  })

  dataColumnsOptionsGroups.unshift({ group: 'Count', options: [columnsNamesEnums.INDEX, columnsNamesEnums.COUNT] })

  const allAvailableFilters = ({ allFiltersConfig, setConfig }) => [
    {
      filterName: columnsNamesEnums.PORTFOLIO,
      mainDataKey: columnsNamesEnums.PORTFOLIO,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.PORTFOLIO,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.PORTFOLIO),
      },
    },
    {
      filterName: columnsNamesEnums.USE_TYPE,
      mainDataKey: columnsNamesEnums.USE_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.USE_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.USE_TYPE),
      },
    },
    {
      filterName: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.ENERGY_EFFICIENCY_CLASS),
      },
      config: allFiltersConfig,
      setConfig,
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_TYPE,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.HEAT_ENERGY_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.HEAT_ENERGY_TYPE),
      },
    },
    {
      filterName: columnsNamesEnums.PREMISE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.PREMISE,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.PREMISE,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.PREMISE], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.FOOTPRINT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.FOOTPRINT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.FOOTPRINT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.FOOTPRINT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.GROSS_AREA_WITH_BASEMENT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT], defaultMin: 0 })
      },
    },

    {
      filterName: columnsNamesEnums.FLOOR_AMOUMT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.FLOOR_AMOUMT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.FLOOR_AMOUMT,
        step: 1,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.FLOOR_AMOUMT], defaultMin: 0
        })
      },
    },

    {
      filterName: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.YEAR_OF_CONSTRUCTION],
          defaultMin: 1900
        }),
      },
    },

    {
      filterName: columnsNamesEnums.STRANDING_YEAR,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.STRANDING_YEAR,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsNamesEnums.STRANDING_YEAR,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.STRANDING_YEAR],
          defaultMin: 1900

        })

      },
    },

    {
      filterName: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION], defaultMin: 0 })

      },
    },

    {
      filterName: columnsNamesEnums.CO2_EMISSION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.CO2_EMISSION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.CO2_EMISSION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.CO2_EMISSION], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A], defaultMin: 0 })
      },
    },
    // ////////////////////////////////////////////////////////////////////////////////////

    {
      filterName: columnsNamesEnums.HERITAGE_PROTECTED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.HERITAGE_PROTECTED, config: allFiltersConfig, setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HERITAGE_PROTECTED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.HERITAGE_PROTECTED),
      },
    },
    {
      filterName: columnsNamesEnums.BUILDING_TYPE_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_TYPE_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_TYPE_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_TYPE_ID),
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_TYPE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_TYPE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_TYPE),
      }
    },
    {
      filterName: columnsNamesEnums.PRE_FABRICATED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.PRE_FABRICATED, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.PRE_FABRICATED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.PRE_FABRICATED),
      }
    },
    {
      filterName: columnsNamesEnums.LOGGIA,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.LOGGIA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.LOGGIA,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.LOGGIA),
      }
    },
    {
      filterName: columnsNamesEnums.BALCONY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BALCONY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BALCONY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BALCONY),
      }
    },
    {
      filterName: columnsNamesEnums.CEILING_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.CEILING_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.CEILING_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.CEILING_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.FACADE_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.FACADE_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.FACADE_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.FACADE_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.NET_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.NET_AREA_WITH_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NET_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.NET_AREA_WITH_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.DISTRICT_HEATING_AVAILABLE),
      }
    },
    {
      filterName: columnsNamesEnums.NATURAL_GAS_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.NATURAL_GAS_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NATURAL_GAS_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.NATURAL_GAS_AVAILABLE),
      }
    },
    {
      filterName: columnsNamesEnums.BOUGHT_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.BOUGHT_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BOUGHT_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.BOUGHT_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.SOLD_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.SOLD_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.SOLD_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.SOLD_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.RENTAL_UNITS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.RENTAL_UNITS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.RENTAL_UNITS,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.RENTAL_UNITS], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_ID),
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_NAME,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_NAME, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_NAME,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_NAME),
      }
    },
    {
      filterName: columnsNamesEnums.ZIP,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.ZIP, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.ZIP,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.ZIP),
      }
    },
    {
      filterName: columnsNamesEnums.CITY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.CITY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.CITY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.CITY),
      }
    },
    {
      filterName: columnsNamesEnums.MAIN_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.MAIN_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.MAIN_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.MAIN_DISTRICT),
      }
    },
    {
      filterName: columnsNamesEnums.SUB_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.SUB_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.SUB_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.SUB_DISTRICT),
      }
    },
    {
      filterName: columnsNamesEnums.FULL_ADDRESS,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.FULL_ADDRESS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.FULL_ADDRESS,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.FULL_ADDRESS),
      }
    },
    {
      filterName: columnsNamesEnums.STREET,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.STREET, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.STREET,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.STREET),
      }
    }

  ]

  const dynamicChartsRowHeight = 50;
  const allSettings = (updateChart, config) => [
    {
      filterName: FiltersNames.TITLE,
      filterLabel: 'Chart title',
      filterType: FilterTypes.INPUT,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        className: "block w-3/4 ",
        label: "Title",
        placeholder: "Title",
        height: "4rem"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
      isInDiagramsPart: false,
    },
    {
      mainDataKey: 'dataPoint',
      useAsDataFilter: false,
      filterName: FiltersNames.DATAPOINT,
      filterLabel: 'Data point',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Data point",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.COMPOSED].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: [columnsNamesEnums.INDEX, ...dataColumns],
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.BAR, chartTypesEnums.AREA, chartTypesEnums.LINE, chartTypesEnums.HEATMAP].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_X_AXIS,
      filterLabel: 'Invert X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert X Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.Y_AXIS,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "y-axis",
        options: [columnsNamesEnums.INDEX, ...dataColumns],
        displayOptions: [columnsNamesEnums.INDEX, ...dataColumns,],
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_Y_AXIS,
      filterLabel: 'Invert Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert Y Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.LEGEND_POSITION,
      filterLabel: 'Lengeds Position',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Lengeds position",
        options: ['bottom', 'right'],
        displayOptions: ['Bottom', 'Right'],
        width: "75%"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.SORT_VALUE,
      filterLabel: 'Sort by',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Sort by",
        options: getSortOptions({ chartType: config.chartType })?.options,
        displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
        className: "block w-3/4 "
      },
      visible: () => {
        return isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
          !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
      },
      isInDiagramsPart: false,
    },
    {
      filterName: settingsOptions.COLOR_BAR_RANGE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      filterLabel: 'Color bar range',
      mainDataKey: columnsNamesEnums.PREMISE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Color bar range",
        className: "block w-3/4 ",
        changeByTyping: true,
        ...findDataRange({ data: filteredData, dataKeys: [config?.dataPoint], defaultMin: 0 })
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.COLOR_BAR_RANGE),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "y-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterLabel: 'Diagram type',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Diagram type",
        options: Object.values(diagramTypes),
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterLabel: 'Partitions',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Partitions",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterLabel: 'Stacks',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Stacks",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterLabel: 'Direction',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Direction",
        options: ["horizontal", "vertical"],
        displayOptions: ["Horizontal", "Vertical"],
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
      isInDiagramsPart: true,
    },
    // {
    //   filterLabel: 'Color',
    //   filterName: FiltersNames.DIAGRAMS_COLOR,
    //   filterType: FilterTypes.COLOR_INPUT,
    //   adjustConfig: (config) => config,
    //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
    //   props: {
    //     className: "block w-3/4 ",
    //   },
    //   visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.COLOR, diagramIndex),
    //   isInDiagramsPart: true,
    // },
  ]
  const useGetSpecificData = (_config) => {
    return { specificData: [], isLoading: false, isError: false }
  }
  const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
    const { dataPoint, chartType, diagrams, xAxis, yAxis } = config

    return JSON.stringify([dataPoint, chartType, diagrams, xAxis, yAxis])
  }, [])

  const dataTransformator = useCallback((data, { dataPoint, chartType, diagrams, xAxis, yAxis }) => {
    let output = []
    const { partitions, stacks } = diagrams?.[0]
    if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = flattenData || []
    }

    else if ([chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.LINE, chartTypesEnums.KPI].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = flattenData || []
    }

    else if (chartType === chartTypesEnums.HEATMAP) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (yAxis) filteredRow[yAxis] = row[yAxis];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      const xLabels = data
        ?.map((row) => row[xAxis])
        ?.filter(distinctFilter);

      const yLabels = data
        ?.map((row) => row[yAxis])
        ?.filter(distinctFilter);

      output = { flattenData: flattenData || [], xLabels, yLabels }
    }

    return output

  }, [])

  const getUnit = useCallback(({ quantity }) => columnsUnits?.[quantity], [])


  const getHoveredItemRelatedItems = ({ hoveredItem }) => {

    if (!hoveredItem) return
    const { params, config, point } = hoveredItem
    const { chartType, diagrams, xAxis, yAxis, } = config
    const xIsNumeric = filteredData?.some(row => isNumeric(row[xAxis]))
    let stacks, partitions, isStacked, isMixed
    if (diagrams?.length) {
      stacks = diagrams[0]?.stacks
      partitions = diagrams[0]?.partitions
      isMixed = partitions && stacks
      isStacked = partitions || stacks
    }

    if (chartType === chartTypesEnums.MINI_MAP || (chartType === chartTypesEnums.COMPOSED && xIsNumeric)) {
      return [point]
    }
    if (chartType === chartTypesEnums.HISTOGRAM) {
      const { rangeString, index, dataPoint } = point
      const valueRange = rangeString?.split('-')?.map(numString => parseFloat(numString))
      const [min, max] = valueRange
      return filteredData.filter((row) => {
        const value = row[dataPoint]
        return (value > min || (value === min && index === 0)) && value <= max
      })
    }
    return filteredData?.filter(row => areItemsSame({ item1: point, item2: row, chartType, xAxis, yAxis, diagrams }))
  }

  const isItemHovered = ({ hoveredItemRelatedItems, hoveredItem, row, chartType, xAxis, yAxis, diagrams }) => {
    let stacks, partitions, isStacked, isMixed
    const xIsNumeric = filteredData?.some(row => isNumeric(row[xAxis]))
    if (diagrams?.length) {
      stacks = diagrams[0]?.stacks
      partitions = diagrams[0]?.partitions
      isMixed = partitions && stacks
      isStacked = partitions || stacks
    }
    const relatedItems = hoveredItemRelatedItems

    if (chartType === chartTypesEnums.MINI_MAP && hoveredItem?.config?.chartType === chartTypesEnums.MINI_MAP)
      return true

    // Hardcoded value
    else if (chartType === chartTypesEnums.MINI_MAP || (chartType === chartTypesEnums.COMPOSED && xIsNumeric)) {
      return relatedItems?.some(item => item?.ID === row?.ID)
    }
    else if (chartType === chartTypesEnums.HISTOGRAM) {
      const { dataPoint, rangeString, index } = row
      const interval = rangeString?.split('-')?.map(numString => parseFloat(numString))
      const [min, max] = interval
      return relatedItems?.some(item => {
        const value = item[dataPoint]
        return (value > min || (value === min && index === 0)) && value <= max
      })
    }
    else {
      return relatedItems?.some(item => areItemsSame({ item1: item, item2: row, chartType, xAxis, yAxis, diagrams }))
    }
  }

  const [triggerFlag, setTriggerFlag] = useState(false)

  const renderMiniMapObjectClickModal = ({ objects: clickedAssets, setObjects: setClickedAssets }) => {
    return clickedAssets?.map((clickedAsset, index) => {
      const id = clickedAsset?.id
      const selectedBuilding = infoBoxesAndTableData?.find(building => building?.[columnsNamesEnums.ID] === id)
      return (
        <AssetCardModal key={id} triggerFlag={`${triggerFlag} ${id}`} onCardClick={() => {
          window.open(`${window.location.origin}/details/${id}/buildingProfile`)
        }}
          onClose={() => setClickedAssets(clickedAssets => clickedAssets.filter(asset => asset?.id !== id))}
          assetCardPosition={clickedAsset?.assetPosition}
          building={selectedBuilding}
        />
      )
    })
  }

  // ADDEDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
  // ADDEDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD
  // ADDEDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD


  return (
    <LoadingOrEmptyWrapper showLoading={isLoading} height="400px">
      <DynamicDashboardMultiTab
        filteredData={filteredData}
        setFilteredData={setFilteredData}
        generalDashboardData={generalDashboardData}
        savedPortfolioAndColorsConfigs={savedPortfolioAndColorsConfigsMemoized}
        defaultPortfolioConfigsAndColorsConfigs={defaultPortfolioConfigsAndColorsConfigs}
        setSaveConfigRerenderTemp={setSaveConfigRerenderTemp}
        filtersCategories={filtersCategories}
        categoricalColumns={categoricalColumns}
        numericalColumns={numericalColumns}
        defaultColors={defaultColors}
        allAvailableFilters={allAvailableFilters}


        dynamicChartsRowHeight={dynamicChartsRowHeight}
        allSettings={allSettings}
        allFilters={() => []}
        useGetSpecificData={useGetSpecificData}
        getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
        dataTransformator={dataTransformator}
        getUnit={getUnit}
        getHoveredItemRelatedItems={getHoveredItemRelatedItems}
        isItemHovered={isItemHovered}

        setTriggerFlag={setTriggerFlag}
        renderMiniMapObjectClickModal={renderMiniMapObjectClickModal}
        IdColumnName={columnsNamesEnums.ID}

        nameForLocalStorage={"portfolioConfigs"}
      />
      <Paper className="mt-8">
        <h4 className="t-heading-m ">
          {tableFilteredData?.length} {t("AssetOverview.filters.buildings")}
        </h4>
        <Table
          className="relative mt-6"
          data={[...tableFilteredData]}
          headers={headers}
          idColumnName="id"
          columnsMinWidth="10rem"
          onRowClick={(row) => {
            window.open(`${window.location.origin}/details/${row[columnsNamesEnums.ID]}/buildingProfile`)
          }}
        />
      </Paper>
    </LoadingOrEmptyWrapper >
  );
};
