import React, { useCallback, useMemo } from "react";
import { createPortal } from "react-dom";
import { CustomModal } from "../CustomModal";
import { GeneralErrorBoundary } from "../ErrorBoundary";
import DynamicChartMemoized from "./DynamicChartMemoized";

export const DynamicChartFullScreenHandlerToBeAdded = ({ children, isFullScreen, className }) => {
  return createPortal(
    <div
      className={className}

      style={{
        padding: 0,
        height: isFullScreen ? '100vh' : '40vh',
        width: isFullScreen ? '100vw' : '40vw',
        position: 'fixed',
        zIndex: 100,
        backgroundColor: 'red',
        top: 0,
        left: 0
      }}
    >
      {children}
    </div>
    , document.body
  )
}


export const DynamicChartFullScreenHandler = ({ children, isFullScreen, className }) => {
  if (isFullScreen)
    return (
      <div
        style={{ width: '100vw', height: '100vh' }}
        className={className}>
        <CustomModal
          width='100vw' height='100vh'
          padding="0"
          closeIcon={false}
          modalOpen={true}
        >
          {children}
        </CustomModal>
      </div>
    )
  else return (
    children
  )
}




export const DynamicChart = ({
  id,
  config,
  removeChart,
  updateChart,
  specificDataGetter,
  fullScreenChart,
  allFilters,
  allSettings,
  ...props
}) => {
  const removeChartMemoized = useCallback(removeChart, []);
  const fullScreenChartMemoized = useCallback(fullScreenChart, [])
  const updateChartMemoized = useCallback((key, value) => updateChart(key, value), []);

  const specificDataGetterMemoized = useCallback(specificDataGetter, [])

  // Pass this parameter to the DynamicChartMemoized only to make a rerender when changed.
  const userLanguage = localStorage.getItem('language')
  const configMemoized = useMemo(() => config, [JSON.stringify(
    Object.entries(config)
      .filter(([key, _value]) => !["x", "y"].includes(key))
    // .map(([_key, value]) => value)
  )]
  )


  const itemFilters = useMemo(() => allFilters(updateChartMemoized, config)?.map(filter => {
    return {
      ...filter, config: filter.adjustConfig(config),
      setConfig: filter.setConfig,
    }
  }), [configMemoized])

  const itemSettings = useMemo(() => allSettings(updateChartMemoized, config)?.map(setting => {
    return {
      ...setting,
      config: setting.adjustConfig(config),
      setConfig: !setting.isInDiagramsPart ? setting.setConfig(updateChartMemoized) : undefined,
      setConfigFn: setting.isInDiagramsPart ? setting.adjustSetConfigFn : undefined
    }
  }), [configMemoized])


  return (
    <GeneralErrorBoundary resetKeys={[config, props]}>
      <DynamicChartMemoized
        config={configMemoized}
        onClose={removeChartMemoized}
        specificDataGetter={specificDataGetterMemoized}
        setConfig={updateChartMemoized}
        setFullScreenChartId={fullScreenChartMemoized}
        id={id}
        userLanguage={userLanguage}
        filters={itemFilters}
        settings={itemSettings}
        {...props}
      />
    </GeneralErrorBoundary>
  );
};
