import { sum } from "lodash";
import { useRef, useState } from "react";


export const handleConfigChange = (index, diagrams, key, newValue, updateChart) => {
    const newConfig = { ...diagrams[index], [key]: newValue };
    const updatedConfig = diagrams?.map((diagram, i) => {
        return i !== index ? diagram : newConfig;
    });
    updateChart('diagrams', updatedConfig);
};


export const diagramInitialConfig = {
    dataPoint: null,
    dataKey: null,
    diagramType: null,
    color: "black",
    partitions: null,
    stacks: null,
    percentageBased: false,
    direction: "horizontal",
    title: null,
};


export const settingsOptions = Object.freeze({
    X: "x",
    Y: "y",
    QUANTITY: "quantity",
    DIAGRAM_TYPE: "diagramType",
    PARTITIONS: "partitions",
    STACKS: "stacks",
    HEIGHT_100: "height100",
    DIRECTION: "direction",
    COLOR: "color",
    SORT_BY: "sortBy",
    LEGENDS_POSITION: "legendsPosition",
    DATA_POINT: 'dataPoint',
    TITLE: 'title',
    COLOR_BAR_RANGE: 'colorBarRange',

    INVERT_X_AXIS: 'invertXAxis',
    INVERT_Y_AXIS: 'invertYAxis',
});


// Remove this hardcoding
export const getFlattenData = ({ data, sensors, valueName }) => {
    const flattenData = [];
    for (const row of data) {
        const rowCopy = { ...row };
        for (const col of sensors) delete rowCopy[col];
        for (const key of Object.keys(row)) {
            if (sensors?.includes(key)) {
                const flatItem = {
                    ...rowCopy,
                    [valueName]: row[key],
                    sensor: key,
                };
                flattenData.push(flatItem);
            }
        }
    }
    return flattenData;
};

export const aggregatedSumValue = ({ data, groupByColumns, aggregateColumns }) => {
    if (!groupByColumns?.filter((e) => e !== null && e !== undefined)?.length) {
        return [
            data?.reduce((acc, cur) => {
                aggregateColumns.forEach((col) => {
                    acc[col] = (acc[col] || 0) + cur[col];
                });
                return acc;
            }, {}),
        ];
    }

    return Object.values(
        data?.reduce((acc, cur) => {
            const key = groupByColumns?.map((col) => cur[col]).join("|");
            if (!acc[key]) {
                acc[key] = Object.fromEntries(
                    groupByColumns?.map((col) => [col, cur[col]])
                );
                aggregateColumns.forEach((col) => {
                    acc[key][col] = cur[col];
                });
            } else {
                aggregateColumns.forEach((col) => {
                    acc[key][col] += cur[col];
                });
            }
            return acc;
        }, {})
    );
};

export const pivotData = (data, pivotColumn, valueColumn) => {
    if (!data?.length || !pivotColumn) return [];
    const pivotedData = {};
    const keyColumns = Object.keys(data[0]).filter(
        (col) => col !== pivotColumn && col !== valueColumn
    );
    data?.forEach((entry) => {
        const key = keyColumns?.map((col) => entry[col]).join("-");
        if (!pivotedData[key]) {
            pivotedData[key] = {};
            keyColumns?.forEach((col) => {
                pivotedData[key][col] = entry[col];
            });
        }
        pivotedData[key][entry[pivotColumn]] = entry[valueColumn];
    });
    return Object.values(pivotedData);
};


export const useDynamicDashboard = ({ defaultChart, defaultDashboardConfigs, chartsConfigs }) => {
    const [allChartsConfigs, setAllChartsConfigs] = useState(chartsConfigs);
    const scrollRef = useRef();

    const addChart = () => {
        const maxY = allChartsConfigs?.length
            ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
            : -1;
        const maxId = allChartsConfigs?.length
            ? Math.max(...allChartsConfigs?.map((l) => l.id))
            : -1;
        const newChart = {
            ...defaultChart,
            y: maxY + 1,
            id: maxId + 1,
        };

        setAllChartsConfigs([...allChartsConfigs, newChart]);
        scrollRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const removeChart = (id) => {
        setAllChartsConfigs((allChartsConfigs) =>
            allChartsConfigs.filter((l) => l.id !== id)
        );
    };

    const updateChart = (id, key, value) => {
        setAllChartsConfigs((allChartsConfigs) => {
            const updatedCharts = allChartsConfigs.map((l) => {
                if (l.id !== id) return l;
                else return { ...l, [key]: value };
            });
            return updatedCharts;
        });
    };

    const resetDashboardConfigs = () => {
        setAllChartsConfigs(defaultDashboardConfigs);
    };

    return { allChartsConfigs, setAllChartsConfigs, updateChart, removeChart, resetDashboardConfigs, addChart, scrollRef }
}


export const useDynamicDashboardMultiTab = ({ defaultChart, defaultDashboardConfigs, allTabschartsConfigs }) => {
    const [allTabsAllChartsConfigs, setAllTabsAllChartsConfigs] = useState(allTabschartsConfigs);
    const scrollRef = useRef();

    const tabAddChart = (tabIndex) => () => {
        const tabAllChartsConfigs = allTabsAllChartsConfigs[tabIndex]
        const maxY = tabAllChartsConfigs?.length
            ? Math.max(...tabAllChartsConfigs?.map((l) => l.y + l.h))
            : -1;
        const maxId = tabAllChartsConfigs?.length
            ? Math.max(...tabAllChartsConfigs?.map((l) => l.id))
            : -1;
        const newChart = {
            ...defaultChart,
            y: maxY + 1,
            id: maxId + 1,
        };

        const newAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
            if (index !== tabIndex) return item
            return [...item, newChart]
        })

        setAllTabsAllChartsConfigs(newAllTabsChartsConfigs);
        scrollRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const tabRemoveChart = (tabIndex) => (id) => {

        setAllTabsAllChartsConfigs((allTabsAllChartsConfigs) => {
            const filteredAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
                if (index !== tabIndex) return item
                return item.filter((l) => l.id !== id)
            })
            return filteredAllTabsChartsConfigs
        });
    };

    const tabUpdateChart = (tabIndex) => (id, key, value) => {
        setAllTabsAllChartsConfigs((allTabsAllChartsConfigs) => {
            const updatedAllTabsChartsConfigs = allTabsAllChartsConfigs.map((item, index) => {
                if (index !== tabIndex) return item
                const updatedCharts = item.map((l) => {
                    if (l.id !== id) return l;
                    else return { ...l, [key]: value };
                });
                return updatedCharts;
            })
        });
    };

    const resetDashboardConfigs = () => {
        setAllTabsAllChartsConfigs(defaultDashboardConfigs);
    };

    return { allTabsAllChartsConfigs, setAllTabsAllChartsConfigs, tabUpdateChart, tabRemoveChart, resetDashboardConfigs, tabAddChart, scrollRef }
}


export const getMapPointColor = ({ value, min, max, colorScale, opacity = 1 }) => {
    value = Math.max(min, Math.min(value, max));

    const ratio = min === max ? 1 : (value - min) / (max - min);
    if (colorScale) {
        const scaled = colorScale(ratio);
        const rgb = scaled._rgb;

        const r = Math.round(255 * (1 - ratio));
        const g = Math.round(255 * ratio);
        const b = 0;

        return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${opacity})`;
    } else return 'rgb(0,0,0,1)'
}


export const getAxisNameWithUnit = ({ dataKey, unit }) => {
    return `${dataKey ? dataKey : ''} ${unit ? "(" + unit + ")" : ''}`
}

export const sortData = ({ data, dataKey, decsending }) => {
    if (!data?.length || !dataKey) return data
    const sorted = [...data]
    sorted.sort((a, b) => {
        if (decsending)
            return a[dataKey] < b[dataKey] ? 1 : -1
        else return a[dataKey] < b[dataKey] ? -1 : 1
    })
    return sorted
}

export const sortDataAccumulative = ({ data, dataKeys, decsending }) => {
    if (!data?.length || !dataKeys?.length) return data
    const sorted = [...data]
    sorted.sort((a, b) => {
        const accA = sum(dataKeys.map(key => a[key]))
        const accB = sum(dataKeys.map(key => b[key]))
        if (decsending)
            return accA < accB ? 1 : -1
        else return accA < accB ? -1 : 1
    })
    return sorted
}




const predefinedSteps = [
    0.001,
    0.002,
    0.005,
    0.01,
    0.02,
    0.05,
    0.1,
    0.2,
    0.5,
    1,
    2,
    5,
    10,
    25,
    50,
    100,
    250,
    500,
    1000,
    2000,
    5000,
    10000,
    25000,
    50000,
    100000,
    250000,
    500000,
    1000000,
    2500000,
    5000000,
    10000000,
    25000000,
    50000000,
];

export const generateAxisValues = (minValue, maxValue, step) => {
    if (minValue === undefined || maxValue === undefined) return;
    let bestStep;
    const range = maxValue - minValue;
    if (step !== undefined) bestStep = step;
    else {
        const ticksNos = [...Array(5).keys()]?.map((e) => e + 5);
        const upperBounds = predefinedSteps?.map((step) => {
            for (const tickNo of ticksNos) {
                if (step * tickNo > range) return step * tickNo;
            }
            return null;
        });
        const bestStepIndex = upperBounds.indexOf(
            Math.min(...upperBounds.filter((e) => e !== null))
        );
        bestStep = predefinedSteps[bestStepIndex];
    }
    if (bestStep === undefined || bestStep === null) return;
    const axisValues = [];
    const roundedMinValue = Math.floor(minValue / bestStep) * bestStep;
    for (let i = 0; i <= Math.ceil(range / bestStep) + 2; i++) {
        axisValues.push(roundedMinValue + i * bestStep);
        if (roundedMinValue + i * bestStep >= maxValue) break;
    }
    return axisValues;
};
