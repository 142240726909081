import { Paper, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useGetAllMeasures, useGetCrrem } from "../../api/hooks/allHooks";
import { theme } from "../../styles/theme";
import { distinctFilter, getSegmentsCrossPointCoords, sum } from "../../utils/dataManipulation";
import { KPIs } from "../BuildingProfile/KPIs";
import Chip from "../Chip";
import { RadioButton } from "../RadioButton";
import LegendBullet from "../chart/LegendBullet";
import { Dropdown } from "../Dropdown";
import { DropdownSingleSelect } from "../DropdownSingleSelect";
import { eventNames, withGAEventTracking } from "../../functions/googleAnalytics";
import { getCrossYear } from "../../functions/Modernization";

// TODOs:
// 1-Better choice of dropdown and oneTime dropdown
// 2-modularize
// 3-new row a part of main table
// 4-new row isloading state
// 5-selected retrofits hover state vice versa
// 7-empty new row onBlur
// 8-selected retrofits row another color
// 9-invalidate selected retro by change in all retro
// 10-userbased remove/modify all retros
// 11-Dropdown full width

const calculateStrandingPoint = (data, dataKey) => {
  const strandingPoints = [];
  if (!data?.length > 0) return [];
  const crossYears = getCrossYear(data, dataKey);
  for (const crossYear of crossYears) {
    const [year, nextYear] = crossYear;
    if (!year || !nextYear) continue;
    const yearDataItems = data.filter(
      (e) =>
        e.year === year && e.crrem !== undefined && e[dataKey] !== undefined
    );
    const nextYearDataItems = data.filter(
      (e) =>
        e.year === nextYear && e.crrem !== undefined && e[dataKey] !== undefined
    );

    if (!nextYearDataItems.length > 0 || !yearDataItems.length > 0) continue;
    const yearData = yearDataItems[yearDataItems.length - 1];
    const nextYearData = nextYearDataItems[0];

    // const yearData = data.find((e) => e.year === year);
    // const nextYearData = data.find((e) => e.year === nextYear);
    // if (!yearData || !nextYearData) continue;

    // if (yearData?.crrem === yearData[dataKey]){
    //   if (nextYearData?.crrem < nextYearData[dataKey]) return [year, yearData[dataKey]];
    //   if (nextYearData?.crrem > nextYearData[dataKey]) return [year, yearData[dataKey]];

    // }
    const lineA = [
      [year, yearData[dataKey]],
      [nextYear, nextYearData[dataKey]],
    ];
    const lineB = [
      [year, yearData?.crrem],
      [nextYear, nextYearData?.crrem],
    ];
    strandingPoints.push(getSegmentsCrossPointCoords(lineA, lineB));
  }
  return strandingPoints;
};

const getRetrofitYCoords = (retrofits, data) => {
  let retrofitYCoords = [];
  retrofits.forEach((retrofit, index) => {
    const sumOfPreviousRetros = sum(
      retrofits.slice(0, index).map((e) => e.amount)
    );
    const retrofitYearData = data?.find((e) => e.year === retrofit.year);
    if (!retrofitYearData) return;
    retrofitYCoords.push([
      retrofitYearData?.current - (sumOfPreviousRetros || 0),
      retrofitYearData?.current - (sumOfPreviousRetros || 0) - retrofit.amount,
    ]);
  });
  return retrofitYCoords;
};

const addRetrofitsToData = (retrofits, data) => {
  if (!retrofits.length > 0) return data;
  retrofits.sort((a, b) => a.year - b.year);
  const retrofitYCoords = getRetrofitYCoords(retrofits, data);
  if (!retrofitYCoords?.length > 0) return data;
  const newData = data.map((e, index) => {
    const modernized =
      e.current -
      sum(
        retrofits
          ?.filter((retro) => e.year > retro.year)
          ?.map((retro) => retro.amount)
      );
    return { ...e, modernized: modernized || e.current };
  });
  retrofitYCoords.forEach((_, index) => {
    newData.push({
      year: retrofits[index].year,
      retrofit: retrofitYCoords[index][0],
    });
    newData.push({
      year: retrofits[index].year,
      retrofit: retrofitYCoords[index][1] + 0.5,
      modernized: retrofitYCoords[index][1],
    });
  });
  newData.sort((a, b) => a.year - b.year);
  return newData;
};

const CustomizedDot = (props) => {
  const { cx, cy, stroke, payload, value, data, dataPoint } = props;

  if (payload.retrofit === undefined || payload[dataPoint] === undefined)
    return;
  return (
    <svg
      x={cx - 6}
      y={cy - 3}
      width={12}
      height={6}
      fill="#00a"
      viewBox="0 0 12 6"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 0.750002L5.5 5.25L10 0.75"
        stroke="#00a"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};

const StrandingChartLegends = ({ className, modernized, quantity, width }) => {
  const legends = [
    {
      color: "var(--clr-secondary-blue-500)",
      label: "Modernized",
      quantity: "consumption",
    },
    {
      color: "var(--clr-plunge-700)",
      label: "Country Specific Energy Target",
      quantity: "consumption",
    },
    {
      color: "var(--clr-mystic-red-500)",
      label: "Stranding Event",
      quantity: "consumption",
    },
    {
      color: "var(--clr-gray-900)",
      label: "Asset Energy Intensity",
      quantity: "consumption",
    },
    {
      color: "var(--clr-secondary-blue-500)",
      label: "Modernized",
      quantity: "emission",
    },
    {
      color: "var(--clr-plunge-700)",
      label: "Decarbonization Target",
      quantity: "emission",
    },
    {
      color: "var(--clr-mystic-red-500)",
      label: "Stranding Event",
      quantity: "emission",
    },
    {
      color: "var(--clr-gray-900)",
      label: "Climate And Grid Corrected Asset Performance",
      quantity: "emission",
    },
    {
      color: "var(--clr-gray-500)",
      label: "Baseline Emission",
      quantity: "emission",
    },
  ];
  return (
    <Stack
      spacing={4}
      className={className}
      style={{ flexWrap: "wrap", width: width, flexDirection: "row-reverse" }}
    >
      {legends?.map((legend, index) => {
        if (
          (legend.label !== "Modernized" || modernized) &&
          legend.quantity === quantity
        )
          return (
            <Stack spacing={2} key={index}>
              <LegendBullet
                width="0.8rem"
                height="0.8rem"
                type="circle"
                color={legend.color}
              />
              <span className="t-body-xl">{legend.label}</span>
            </Stack>
          );
      })}
    </Stack>
  );
};

const CustomTooltip = ({ active, payload, label, quantity }) => {
  if (active && payload && payload.length) {
    if (payload[0].payload.crrem !== undefined)
      return (
        <Paper
          className="t-heading-l0 p-4"
          style={{
            backgroundColor: "#fff",
          }}
        >
          <p className="mt-1 text-plunge-700 ">{`Target: ${payload[0].payload.crrem?.toFixed(
            2
          )}`}</p>
          <p className="mt-1 text-gray-900">{`${quantity === "consumption"
            ? "Asset Energy Intensity"
            : "Climate And Grid Corrected Asset Performance"
            }: ${payload[0].payload.current?.toFixed(2)}`}</p>
          {quantity === "emission" && (
            <p className="mt-1 text-gray-500">{`Baseline Emission: ${payload[0].payload.baseline?.toFixed(
              2
            )}`}</p>
          )}
          {payload[0].payload?.modernized !== undefined && (
            <p className="mt-1 text-blue-500">{`Retrofit: ${payload[0].payload.modernized?.toFixed(
              2
            )}`}</p>
          )}
          <p className="mt-1 text-[black]">{`Year: ${label}`}</p>
        </Paper>
      );
  }

  return null;
};

export const StrandingChart = ({
  className,
  projectId,
  selectedMeaures,
  kpis,
  kpiIsLoading,
  onCrremChange,
  chartHeight = 400,
  legendsWidth,
}) => {

  const crremScenariosOptions = [
    {
      option: '1.5',
      displayOption: '1.5°C Pathway',
    },
    {
      option: '1.5 GEG',
      displayOption: '1.5°C GHGe pathway',
    },
  ]
  const defaultCrremScenario = crremScenariosOptions[0].option
  const [crremScenario, setCrremScenario] = useState(defaultCrremScenario)
  const isScenarioOne = crremScenario === defaultCrremScenario

  const [quantity, setQuantity] = useState("emission");

  const unit = quantity === 'emission' ? 'CO2/m²' : 'kWh/m²'

  const { data: crremDataRaw } = useGetCrrem(
    projectId,
    isScenarioOne ? "1.5" : "1.5 GEG",
    quantity
  );
  const crremData = crremDataRaw?.map((row) => {
    return { ...row, baseline: row.current, current: row.climate };
  });
  var dataInit = crremData || [];

  const [data, setData] = useState([]);

  useEffect(() => {
    setData(crremData || []);
    if (onCrremChange) onCrremChange(crremData);
  }, [crremDataRaw]);

  const { data: measures } = useGetAllMeasures(projectId);
  const [retrofits, setRetrofits] = useState(selectedMeaures);
  useEffect(() => {
    const retrofitsWithAmount = selectedMeaures?.map((retrofit) => {
      const correspondingRetrofit = measures?.find(
        (measure) => measure.name === retrofit.name
      );
      if (correspondingRetrofit) {
        const amount =
          quantity === "consumption"
            ? correspondingRetrofit?.embodied_carbon
            : correspondingRetrofit?.carbon_dioxide_reduction;
        return { ...retrofit, amount: Number(amount) };
      }
      return retrofit;
    });
    setRetrofits(retrofitsWithAmount);
  }, [selectedMeaures, quantity, isScenarioOne, measures]);
  // const strandingPoint = calculateStrandingPoint(data);
  const [strandingPoint, setStrandingPoint] = useState([]);
  const [strandingPointsModernized, setStrandingPointsModernized] = useState(
    []
  );

  useEffect(() => {
    const selectedRetrofits = (retrofits || [])
      .map((retrofit) => ({ ...retrofit, year: Number(retrofit.year) }))
      .filter((retrofit) => retrofit.year >= 2020 && retrofit.year <= 2130);
    setData(addRetrofitsToData(selectedRetrofits, dataInit));
  }, [retrofits, crremDataRaw, quantity, isScenarioOne]);

  useEffect(() => {
    const enhancedData = data.map((e) => {
      if (e.crrem !== undefined) return e;
      const year = e.year;
      const yearData = data.find(
        (e) => e.year === year && e.crrem !== undefined
      );
      if (yearData) return { ...e, crrem: yearData.crrem };
      return e;
    });
    setStrandingPointsModernized(
      calculateStrandingPoint(enhancedData, "modernized")
    );
    setStrandingPoint(calculateStrandingPoint(data, "current"));
  }, [data]);

  return (
    <Paper className={`relative  ${className}`}>
      <Stack gap={theme.spacing(6)} alignItems="center" className="mb-9">
        <h2 className="capitalize t-heading-l">Building Energy Performance</h2>
        <RadioButton
          options={["emission", "consumption"]}
          displayOptions={["CO₂ Emission", "kWh"]}
          row={true}
          value={quantity}
          setValue={(newValue) => withGAEventTracking(eventNames.CRREM_QUANTITY_CHANGE, {}, () => setQuantity(newValue))}
        />
      </Stack>

      <section className="flex justify-between items-start gap-6">
        <div style={{ width: kpis ? "calc(100% - 28rem)" : "100%" }}>
          <Stack justifyContent="space-between" alignItems="flex-start">
            <DropdownSingleSelect
              className="mb-6"
              width={'20rem'}
              selectedValue={crremScenario}
              setSelectedValue={(newValue) => withGAEventTracking(eventNames.CRREM_SCENARIO_CHANGE, {},
                () => setCrremScenario(newValue))}
              label='Scenario'
              options={crremScenariosOptions.map(e => e.option)}
              displayOptions={crremScenariosOptions.map(e => e.displayOption)}
            />
            <StrandingChartLegends
              modernized={selectedMeaures?.length > 0}
              quantity={quantity}
              width={legendsWidth}
            />
          </Stack>

          <ResponsiveContainer width="100%" height={chartHeight}>
            <ComposedChart
              data={data}
              margin={{
                top: 30,
                right: 50,
                bottom: 40,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis
                dataKey="year"
                type="number"
                style={{
                  fontFamily: "Exo 2",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  lineHeight: "1.4rem",
                  letterSpacing: 0,
                }}
                domain={["dataMin", "dataMax"]}
                angle={270}
                tickCount={30}
                tickMargin={18}
              >
                <Label
                  position="bottom"
                  className="t-heading-m"
                  offset={20}
                  style={{
                    textAnchor: "start",
                  }}
                  angle={0}
                  value={'Year'}
                />
              </XAxis>
              <YAxis
                type="number"
                style={{
                  fontFamily: "Exo 2",
                  fontSize: "1.2rem",
                  fontWeight: 400,
                  lineHeight: "1.4rem",
                  letterSpacing: 0,
                }}
                tickCount={5}
              // tickMargin={18}
              // ticks={[10, 30, 90, 200, 300]}
              >
                <Label
                  position="top"
                  offset={15}
                  className="t-subheading-m"
                  style={{
                    textAnchor: "start",
                  }}
                  angle={0}
                  value={unit}
                />
              </YAxis>
              <Tooltip content={<CustomTooltip quantity={quantity} />} />
              {/* <Legend
                  align="right"
                  verticalAlign="top"
                  iconSize={9}
                  iconType="circle"
                /> */}
              {strandingPoint?.length > 0 && (
                <ReferenceDot
                  x={strandingPoint[0][0]}
                  y={strandingPoint[0][1]}
                  r={8}
                  fill="none"
                  stroke="var(--clr-mystic-red-500)"
                  strokeWidth={3}
                  style={{
                    filter: "drop-shadow(0px 4px 4px rgba(255, 83, 0, 0.25))",
                  }}
                />

                // <StrandingPoint
                //   x={strandingPoint[0][0]}
                //   y={strandingPoint[0][1]}
                // />
              )}
              {strandingPointsModernized?.length > 0 &&
                strandingPointsModernized.map((sP) => {
                  return (
                    <ReferenceDot
                      x={sP[0]}
                      y={sP[1]}
                      r={8}
                      fill="none"
                      stroke="var(--clr-mystic-red-500)"
                      strokeWidth={3}
                      style={{
                        filter:
                          "drop-shadow(0px 4px 4px rgba(255, 83, 0, 0.25))",
                      }}
                    />
                  );
                })}
              {quantity === "emission" && (
                <Line
                  type="linear"
                  dataKey="baseline"
                  stroke="var(--clr-gray-500)"
                  dot={false}
                  strokeDasharray={5}
                  strokeWidth={1}
                  connectNulls
                />
              )}
              <Line
                type="linear"
                dataKey="current"
                stroke="var(--clr-gray-900)"
                dot={false}
                strokeWidth={1}
                connectNulls
              />
              <Line
                type="linear"
                dataKey="modernized"
                stroke="var(--clr-secondary-blue-500)"
                dot={false}
                strokeWidth={2}
              />
              <Area
                type="linear"
                dataKey="crrem"
                stroke="var(--clr-plunge-700)"
                dot={false}
                strokeWidth={1}
                connectNulls
                fillOpacity={0.05}
                fill="var(--clr-plunge-700)"
              />
              <Line
                type="linear"
                dataKey="retrofit"
                stroke="#00a"
                dot={<CustomizedDot dataPoint="modernized" data={data} />}
                strokeWidth={2}
                isAnimationActive={false}
                strokeDasharray="1"
              />
              {/* <Scatter dataKey="crrem" fill="red" /> */}
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        {kpis && (
          <div className="w-70">
            <KPIs kpis={kpis || []} isLoading={kpiIsLoading} xs={12} />
          </div>
        )}
      </section>
    </Paper>
  );
};
