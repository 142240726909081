import { Accordion, AccordionDetails, AccordionSummary, Breadcrumbs, Grid, Menu, MenuItem, Paper, Stack } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-grid-layout/css/styles.css";
import { useTranslation } from "react-i18next";
import { GrPowerReset } from "react-icons/gr";
import { TfiSave } from "react-icons/tfi";
import "rsuite/dist/rsuite-no-reset.min.css";
import { LoadingOrEmptyWrapper } from "../components/LoadingAndEmptyHandler";
import { deepEqual, distinctFilter, findDataRange, getUniqueValuesFromJson, isNumeric } from "../utils/dataManipulation";

import "react-resizable/css/styles.css";
import { ButtonNew, Chip, CustomModal, Table } from "../components";
// import { GeneralErrorBoundary } from "../ErrorBoundary";
import { isArray } from "lodash";
import { useSelector } from "react-redux";
import { useGetAllBuildings } from "../api/hooks/allHooks";
import { Icon } from "../components";
import { AssetCardModal } from "../components/AssetOverview/AssetCardModal";
import { Divider } from "../components/Divider";
import { DynamicFilterFactory } from "../components/DynamicFormInput";
import { ColorInput } from "../components/Input";
import { ScrollableTabsButtonAuto } from "../components/Tabs";
import { DynamicDashboard } from "../components/chart/DynamicDashboard";
import { areItemsSame, categoricalColumns, columnsNamesEnums, columnsUnits, dataMapping, dataNeededColumns, defaultColors, FiltersNames, infoBoxAndTableNeededColumns, isRelevantOption, } from "../enums/AssetOverview/portfolio";
import { FilterTypes } from "../enums/components";
import { chartTypesEnums, diagramTypes, getSortOptions, valuesEnums, colorCategoriesEnums } from "../enums/dynamicDashboard";
import { theme } from "../styles/theme";
import { diagramInitialConfig, settingsOptions } from "../utils/dynamicDashboard";
import { LocalStorageService } from "../utils/localStorage";

const filtersCategories = [
  {
    categoryName: 'Building profile',
    filtersNames: [
      columnsNamesEnums.PORTFOLIO,
      columnsNamesEnums.BUILDING_ID,
      columnsNamesEnums.BUILDING_NAME,
      columnsNamesEnums.USE_TYPE,
      columnsNamesEnums.BOUGHT_ON,
      columnsNamesEnums.SOLD_ON,
      columnsNamesEnums.RENTAL_UNITS,
      columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      columnsNamesEnums.FLOOR_AMOUMT,
      columnsNamesEnums.HERITAGE_PROTECTED,
      columnsNamesEnums.BUILDING_TYPE_ID,
      columnsNamesEnums.BUILDING_TYPE,
      columnsNamesEnums.PRE_FABRICATED,
      columnsNamesEnums.LOGGIA,
      columnsNamesEnums.BALCONY,
      columnsNamesEnums.FOOTPRINT,
      columnsNamesEnums.CEILING_AREA,
      columnsNamesEnums.FACADE_AREA,
      columnsNamesEnums.PREMISE,
      columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      columnsNamesEnums.NET_AREA_WITH_BASEMENT,
      columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      // columnsNamesEnums.ACCOUNTING_AREA,

    ],
  },
  {
    categoryName: 'Geography',
    filtersNames: [
      columnsNamesEnums.ZIP,
      columnsNamesEnums.CITY,
      columnsNamesEnums.MAIN_DISTRICT,
      columnsNamesEnums.SUB_DISTRICT,
      columnsNamesEnums.FULL_ADDRESS,
      columnsNamesEnums.STREET,
    ],
  },
  {
    categoryName: 'Energy & CO2',
    filtersNames: [
      columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      columnsNamesEnums.HEAT_ENERGY_TYPE,
      columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
      columnsNamesEnums.NATURAL_GAS_AVAILABLE,
      columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      columnsNamesEnums.CO2_EMISSION,
      columnsNamesEnums.STRANDING_YEAR,
    ],
  },
]

const defaultPortfolioConfigs = [
  {
    "tab": "Portfolio summary",
    "filters": {
      "Energy Efficiency class": [
        null,
        "C",
        "A",
        "B"
      ],
      "Gross area": [
        29000,
        51430
      ],
      "Energy consumption": [
        3.63,
        66
      ],
      "Heat energy consumption": [
        16.35,
        123
      ],
      "Electrictiy consumption": [
        48.95,
        54
      ]
    },
    "settings": [
      {
        "type": "1",
        "id": 1,
        "x": 7,
        "y": 5,
        "w": 5,
        "h": 6,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": "Footprint area",
            "diagramType": "scatter",
            "color": "#0adb53",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "composed",
        "title": "Footprint VS Premise",
        "xAxis": "Premise area",
        "yAxis": null,
        "dataPoint": null,
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 2,
        "x": 7,
        "y": 0,
        "w": 5,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": "CO2 Emissions",
            "diagramType": "scatter",
            "color": "#f91010",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "bar",
        "title": "CO2 Emissions per Usage",
        "xAxis": "Main usage",
        "yAxis": null,
        "dataPoint": "Gross area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 3,
        "x": 0,
        "y": 5,
        "w": 7,
        "h": 6,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "#f61313",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "heatmap",
        "title": "Gross area ",
        "xAxis": "Energy Efficiency class",
        "yAxis": "Main usage",
        "dataPoint": "Gross area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 4,
        "x": 4,
        "y": 0,
        "w": 3,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": "Main usage",
            "stacks": null,
            "percentageBased": false,
            "direction": "vertical",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "kpi",
        "title": "Footprint area",
        "xAxis": null,
        "yAxis": null,
        "dataPoint": "Footprint area",
        "legendsPosition": "bottom"
      },
      {
        "type": "1",
        "id": 5,
        "x": 0,
        "y": 0,
        "w": 4,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "miniMap",
        "title": "Heat Energy Consumption",
        "xAxis": null,
        "yAxis": null,
        "dataPoint": "Heat energy consumption",
        "legendsPosition": "bottom"
      }
    ],
    "selectedFiltersNames": []
  },
  {
    "filters": {
      "Main usage": [
        "Office"
      ]
    },
    "settings": [
      {
        "type": "1",
        "id": 0,
        "x": 0,
        "y": 0,
        "w": 4,
        "h": 5,
        "minW": 3,
        "minH": 3,
        "maxH": 20,
        "draggable": true,
        "diagrams": [
          {
            "dataPoint": null,
            "dataKey": null,
            "diagramType": null,
            "color": "black",
            "partitions": null,
            "stacks": null,
            "percentageBased": false,
            "direction": "horizontal",
            "title": null
          }
        ],
        "sortValue": null,
        "chartType": "bar",
        "title": "Energy Consumption",
        "xAxis": "Heat energy type",
        "yAxis": null,
        "dataPoint": "Energy consumption",
        "legendsPosition": "bottom"
      }
    ],
    "selectedFiltersNames": [
      "Main usage"
    ],
    "tab": "Office"
  }
]

const defaultPortfolioConfigsAndColorsConfigs = { colors: { ...defaultColors }, tabs: defaultPortfolioConfigs }

const defaultMinW = 3;
const defaultMinH = 3;

const defaultChart = {
  type: "1",
  // i: "0",
  id: 0,
  x: 0,
  y: 0,
  w: defaultMinW + 1,
  h: defaultMinH + 2,
  minW: defaultMinW,
  minH: defaultMinH,
  maxH: 20,
  draggable: true,
  diagrams: [diagramInitialConfig],
  sortValue: null,
  chartType: null,
  title: null,
  xAxis: null,
  yAxis: null,
  dataPoint: null,
  legendsPosition: 'bottom',
};


const loadDashboardConfigs = () => {
  let dashboardConfigs = localStorage.getItem("portfolioConfigs");
  if (dashboardConfigs) {
    dashboardConfigs = JSON.parse(dashboardConfigs);
  } else {
    dashboardConfigs = defaultPortfolioConfigsAndColorsConfigs;
  }
  return dashboardConfigs
};


const handleConfigChange = (index, diagrams, key, newValue, updateChart) => {
  const newConfig = { ...diagrams[index], [key]: newValue };
  const updatedConfig = diagrams?.map((diagram, i) => {
    return i !== index ? diagram : newConfig;
  });
  updateChart('diagrams', updatedConfig);
};


const DashboardTab = ({ value, setValue, onDelete, hadDeleteIcon }) => {
  const onChange = (e) => {
    setValue(e.target.value)
  }

  const [hovered, setHovered] = useState(false)

  return (
    <>
      {hovered &&
        <input
          style={{
            border: 'none'
          }}
          className="w-40 h-8 p-2 text-center t-subheading-m capitalize bg-transparent"
          value={value}
          onChange={onChange}
          onBlur={() => {
            setHovered(false)
          }}
        />
      }
      {!hovered &&
        <span
          onClick={() => setHovered(true)}
          // onDoubleClick={() => setHovered(false)}
          className="w-40 h-8 text-center p-2 t-subheading-m capitalize bg-transparent cursor-pointer"
        >
          {value}
        </span>
      }
      {hadDeleteIcon &&
        <Icon onClick={onDelete} iconName={'Close'} color={'white'}
          className='absolute top-2 right-2 w-[1rem] h-[1rem]' />
      }
    </>
  )

}


const Header = ({ portfolioConfigs,
  defaultDashboardConfigsHasChanged,
  dashboardConfigsHasChanged,
  onResetDashboardConfigs,
  saveDashboardConfigs,
  addChart,
  tabs,
  addTab,
  deleteTab,
  updateTabName,
  currentTabIndex,
  setCurrentTabIndex,
  onOpenColors
}) => {
  const { t } = useTranslation()

  const tabsLabelsComponents = tabs?.map((tab, index) => {
    return {
      label: <DashboardTab
        value={tab}
        setValue={(newValue) => { updateTabName(newValue, index) }}
        onDelete={() => deleteTab(index)}
        hadDeleteIcon={!(tabs?.length === 1)}
      />
    }
  })

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Stack className=" -ml-4 justify-between">
      <ScrollableTabsButtonAuto
        tabs={tabs}
        addTab={addTab}
        tabsLabelsComponents={tabsLabelsComponents}
        setCurrentTabIndex={setCurrentTabIndex}
        currentTabIndex={currentTabIndex}
        className=' flex-1 max-w-[800px]' />

      <Stack gap={4} className="items-center justify-end w-150">
        <ButtonNew
          variant="primary"
          size="md"
          onClick={onOpenColors}
        >
          <Stack gap={2}>
            <Icon iconName={'ColorPalette'} svgClassName="w-4 h-4" />
            <span>Colors</span>
          </Stack>
        </ButtonNew>

        {defaultDashboardConfigsHasChanged && (
          <>
            <CustomModal modalOpen={modalOpen} setModalOpen={setModalOpen} height={300}>
              <Stack flexDirection={'column'} className="justify-between h-full -mt-4">
                <Stack flexDirection={'column'} className="t-heading-m">
                  <Icon className={'mb-4 !h-10 !w-10'} svgClassName={'!h-10 !w-10'} iconName={'Info'} color={'var(--clr-mystic-red-500)'} />
                  <h3>{t('Portfolio.resetSettings')}</h3>
                  <p className="t-subheading-m text-red-500">{t('Portfolio.settingsWillBeGone')}</p>
                </Stack>
                <Stack className="gap-8">
                  <ButtonNew
                    variant="secondary"
                    size="md"
                    onClick={() => setModalOpen(false)}
                    className={'capitalize'}
                  >
                    {t('general.cancel')}
                  </ButtonNew>

                  <ButtonNew
                    variant="primary"
                    size="md"
                    onClick={() => {
                      onResetDashboardConfigs()
                      setModalOpen(false)
                    }}
                  >
                    {t('general.resetAnyway')}
                  </ButtonNew>
                </Stack>
              </Stack>

            </CustomModal>
            <ButtonNew
              variant="primary"
              size="md"
              onClick={() => setModalOpen(true)}
            >
              <Stack gap={2}>
                <GrPowerReset className="cursor-pointer w-4 h-4" />
                <span>Reset To Default</span>
              </Stack>
            </ButtonNew>
          </>
        )}
        {dashboardConfigsHasChanged && (
          <>
            <ButtonNew
              variant="primary"
              size="md"
              onClick={() => saveDashboardConfigs(portfolioConfigs)}
            >
              <Stack gap={2}>
                <TfiSave className="cursor-pointer w-4 h-4" />
                <span>Save</span>
              </Stack>
            </ButtonNew>
          </>
        )}
        <ButtonNew variant="primary" size="md" onClick={addChart}>
          +Add new chart
        </ButtonNew>
      </Stack>
    </Stack>

  )


}


const Filters = ({ filterCategories, removeFilterName, addFilterName, data, setFilteredData, setConfig }) => {

  const [showID, setShowID] = useState(undefined)

  const [anchorEl, setAnchorEl] = useState(null);
  const [showFiltersModal, setShowFiltersModal] = useState(false)

  const filtersDefaultVisibility = LocalStorageService.getItem(LocalStorageService.PORTFOLIO_FILTERS_VISIBILITY)

  const [filtersInfoIsVisible, setFiltersInfoIsVisible] = useState(filtersDefaultVisibility)
  const [activeFilterCategoryName, setActiveFilterCategoryName] = useState(filterCategories?.[0]?.categoryName)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (!data?.length) return

    let partiallyFilteredData = [...data]
    filterCategories.forEach(filterCategory => {
      filterCategory?.filters
        ?.filter(filter => filterCategory.selectedFiltersNames?.includes(filter?.filterName))
        ?.forEach(filter => {
          const { filterType, mainDataKey, ...args } = filter
          if (!mainDataKey) return
          const filterFactory = new DynamicFilterFactory(filterType, args)
          const filterInstance = filterFactory.createFilterClassInstance()
          partiallyFilteredData = filterInstance.filterData(partiallyFilteredData, mainDataKey)
        })
    })

    setFilteredData(partiallyFilteredData)
  }, [data, filterCategories])

  return (
    <>
      <div className="relative pl-4 ">

        {/* Filters Info  */}
        <Stack className={`py-4 items-start ${!filtersInfoIsVisible && 'w-0 overflow-hidden h-5'}`}
        >
          <ButtonNew
            variant="secondary"
            size="md"
            onClick={() => setShowFiltersModal(true)}
            className='mr-8'
          >
            <Stack gap={2}>
              <Icon size={'md'} iconName={'Filter'} />
              <span>Filters</span>
            </Stack>
          </ButtonNew>

          <Stack className="flex-wrap items-start gap-4">
            {filterCategories?.map((filterCategory, index) => {
              return filterCategory?.selectedFiltersNames?.map(name => {
                const filter = filterCategory?.filters?.find(filter => filter?.filterName === name)
                let { filterType, ...args } = filter
                args = {
                  ...args, props: {
                    ...args.props,
                    onContextMenu: (e) => {
                      e.preventDefault();
                      setActiveFilterCategoryName(filterCategory.categoryName)
                      setShowFiltersModal(true)
                    },
                  }
                }
                const filterFactory = new DynamicFilterFactory(filterType, args)
                const filterInstance = filterFactory.createFilterClassInstance()
                if (filterInstance) {
                  const FilterComponent = filterInstance.createComponent()
                  return (
                    <Stack className="gap-1 w-80">
                      <Icon size={'md'} iconName={'Remove'} color='var(--clr-mystic-red-500)'
                        onClick={() => {
                          removeFilterName(name)
                        }}
                      />
                      {FilterComponent}
                    </Stack>
                  )


                }
              }


              )
            }
            )}

          </Stack >


        </Stack>

        {/* Filters fold/expand icon */}
        <Stack className={`w-4 min-h-[2.4rem] absolute z-100 bg-blue-50 top-1 bottom-1 left-0 -ml-4 
          items-center justify-center t-numbers-m hover:bg-blue-200 cursor-pointer 
          `}
          onClick={() => {
            setFiltersInfoIsVisible(filtersInfoIsVisible => !filtersInfoIsVisible)
            LocalStorageService.setItem(LocalStorageService.PORTFOLIO_FILTERS_VISIBILITY, !filtersInfoIsVisible)
          }}
        >
          <Icon size={'sm'} iconName={'ChevronRight'} className={`${filtersInfoIsVisible && 'rotate-180'} `} />
        </Stack>
      </div>

      {/* Filters Modal */}
      <CustomModal
        modalOpen={showFiltersModal}
        setModalOpen={setShowFiltersModal}
        width='60rem'
        height='90%'
        top={24}
        right={24}
        left='unset'
        style={{ transform: 'none' }}
      >
        <h3 className="t-heading-l">Filter Catergories</h3>
        <Stack className="py-4 flex-col gap-2 items-center w-full">
          <Grid className="w-full" container spacing={4}>
            {filterCategories?.map((filterCategory, index) => {
              const isActive = filterCategory.categoryName == activeFilterCategoryName
              return <Grid item xs={4}>
                <Chip
                  isOn={isActive}
                  onClick={() => setActiveFilterCategoryName(filterCategory.categoryName)}
                >
                  <span>{filterCategory.categoryName}</span>
                </Chip>
              </Grid>

            })}

          </Grid>

          <Divider className='mt-4' />
          <Stack className="mt-8 w-full flex-col items-center">

            {filterCategories?.map((filterCategory, index) => {
              if (filterCategory.categoryName === activeFilterCategoryName)
                return (
                  <Stack className="w-full px-3 gap-2" flexDirection={'column'}>
                    <ButtonNew
                      onClick={(e) => {
                        setShowID(index)
                        handleClick(e)
                      }}
                      variant="secondary"
                      size="sm"
                      className="mt-1  mb-4"
                      id={"basic-button" + index}
                      aria-controls={(showID === index && open) ? ('basic-menu' + index) : undefined}
                      aria-haspopup="true"
                      aria-expanded={(showID === index && open) ? 'true' : undefined}
                    >
                      + Add filter
                    </ButtonNew>
                    <Menu
                      id={"basic-menu" + index}
                      anchorEl={anchorEl}
                      open={showID === index && open}
                      onClose={handleClose}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button' + index,
                        disablePadding: true
                      }}
                      sx={{
                        '.MuiMenu-paper': {
                          padding: '8px 0'
                        }
                      }}
                    >
                      {filterCategory?.filters?.map(f => {
                        if (filterCategory.selectedFiltersNames?.includes(f?.filterName)) return
                        return (
                          <MenuItem className=""
                            onClick={() => {
                              handleClose()
                              setShowID(undefined)
                              setConfig(f?.filterName, undefined)
                              addFilterName(f?.filterName)
                            }
                            }>
                            <Stack className="w-full justify-between gap-8 t-body-l">
                              <span>
                                {f?.filterName}
                              </span>
                              <span>+</span>
                            </Stack>
                          </MenuItem>

                        )
                      })}
                    </Menu>
                    {
                      filterCategory?.selectedFiltersNames?.map(name => {
                        const filter = filterCategory?.filters?.find(filter => filter?.filterName === name)
                        let { filterType, ...args } = filter
                        if (filterType === FilterTypes.NUMERIC_RANGE_PICKER)
                          args = { ...args, props: { ...args.props, changeByTyping: true } }
                        const filterFactory = new DynamicFilterFactory(filterType, args)
                        const filterInstance = filterFactory.createFilterClassInstance()
                        if (filterInstance) {
                          const FilterComponent = filterInstance.createComponent()
                          return (
                            <Stack className="gap-1 w-full">
                              <Icon size={'md'} iconName={'Remove'} color='var(--clr-mystic-red-500)'
                                onClick={() => {
                                  removeFilterName(name)
                                }}
                              />
                              {FilterComponent}
                            </Stack>
                          )


                        }
                      })
                    }

                  </Stack>

                )
            }
            )}
          </Stack>

        </Stack >
      </CustomModal>

    </>

  )
}





const adjustConfigs = (configs) => {
  return JSON.stringify(configs)
}


export const Portfolio = ({ }) => {
  const { t } = useTranslation()

  const { user } = useSelector((store) => store.user);
  const { data, isLoading } = useGetAllBuildings(user?.user_id);

  const dataPreprocessing = useMemo(() => {
    const mappedData = []
    const mappedInfoBoxAndTableData = []
    data?.forEach((row) => {
      const mappedDataRow = {}
      const mappedInfoBoxAndTableRow = {}
      Object.keys(row).forEach((oldDataKey) => {
        let value = row[oldDataKey]

        const newDataKey = dataMapping[oldDataKey]
        if (categoricalColumns.includes(newDataKey)) {
          value = value ?? valuesEnums.NO_DATA
        }
        if (infoBoxAndTableNeededColumns.includes(newDataKey)) {
          mappedInfoBoxAndTableRow[newDataKey] = value
        }

        if (dataNeededColumns.includes(newDataKey)) {
          mappedDataRow[newDataKey] = value
        }
        mappedDataRow[columnsNamesEnums.COUNT] = 1
      })

      mappedInfoBoxAndTableData.push(mappedInfoBoxAndTableRow)
      mappedData.push(mappedDataRow)
    })

    return [mappedData, mappedInfoBoxAndTableData]

  }, [data])

  const [generalDashboardData, infoBoxesAndTableData] = useMemo(() => dataPreprocessing, [dataPreprocessing])


  let dataColumns = [],
    numericalColumns = [];
  if (generalDashboardData?.length) {
    dataColumns = Object.keys(generalDashboardData[0]);
    numericalColumns = dataColumns.filter(col => !categoricalColumns.includes(col))
  }

  let savedPortfolioAndColorsConfigs = loadDashboardConfigs()

  if (isArray(savedPortfolioAndColorsConfigs)) {
    savedPortfolioAndColorsConfigs = { colors: {}, tabs: savedPortfolioAndColorsConfigs }
  }

  const userColors = useMemo(() => savedPortfolioAndColorsConfigs?.colors, [])

  const getAllDataEntities = ({ data, categoricalColumns, numericalColumns }) => {
    const entities = {}
    entities[colorCategoriesEnums.COLUMNS] = {}
    numericalColumns?.forEach((col) => { entities[colorCategoriesEnums.COLUMNS][col] = undefined })

    entities[colorCategoriesEnums.VALUES] = {}
    categoricalColumns?.forEach((col) => {
      entities[colorCategoriesEnums.VALUES][col] = {}
      const columnUniqueValues = data?.map(row => row[col])?.filter(distinctFilter)
      columnUniqueValues?.forEach(val => { entities[colorCategoriesEnums.VALUES][col][val] = undefined })
    })

    return entities
  }

  const mergeColorConfigs = (configs) => {
    const mergedConfigs = {}

    mergedConfigs[colorCategoriesEnums.COLUMNS] = configs?.reduce((total, current) => {
      return { ...total, ...current?.[colorCategoriesEnums.COLUMNS] }
    }, {})

    mergedConfigs[colorCategoriesEnums.VALUES] = configs?.reduce((total, current) => {
      const merged = {}
      const totalKeys = Object.keys(total)
      totalKeys?.forEach(key => {
        merged[key] = { ...total[key], ...(current?.[colorCategoriesEnums.VALUES]?.[key] || {}) }
      })
      return { ...current?.[colorCategoriesEnums.VALUES], ...merged }
    }, {})

    mergedConfigs[colorCategoriesEnums.MIXED] = configs?.reduce((total, current) => {
      const merged = {}
      const totalKeys = Object.keys(total)
      totalKeys?.forEach(key => {
        merged[key] = { ...total[key], ...(current?.[colorCategoriesEnums.MIXED][key] || {}) }
      })
      return { ...current?.[colorCategoriesEnums.MIXED], ...merged }
    }, {})

    return mergedConfigs
  }

  const allDataEntities = getAllDataEntities({ data: generalDashboardData, categoricalColumns, numericalColumns })

  const [colorsConfigs, setColorsConfigs] = useState({})
  const [portfolioConfigs, setPortfolioConfigs] = useState(savedPortfolioAndColorsConfigs?.tabs)

  useEffect(() => {
    const mergedColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, userColors])
    setColorsConfigs(mergedColorConfigs)
  }, [generalDashboardData, userColors])


  const saveDashboardConfigs = () => {
    // localStorage.setItem("portfolioConfigs", JSON.stringify(portfolioConfigs));
    localStorage.setItem("portfolioConfigs", JSON.stringify({ colors: colorsConfigs, tabs: portfolioConfigs }));
    b(!a);
  };

  const onResetDashboardConfigs = () => {
    setPortfolioConfigs(defaultPortfolioConfigsAndColorsConfigs?.tabs)

    const mergedDefaultColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, defaultPortfolioConfigsAndColorsConfigs?.colors])
    setColorsConfigs(mergedDefaultColorConfigs)
    localStorage.removeItem("portfolioConfigs");
  };

  const [a, b] = useState(false);

  const mergedColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, savedPortfolioAndColorsConfigs?.colors])
  const dashboardConfigsHasChanged = !deepEqual(
    adjustConfigs({ colors: mergedColorConfigs, tabs: savedPortfolioAndColorsConfigs?.tabs }),
    adjustConfigs({ colors: colorsConfigs, tabs: portfolioConfigs }))

  const mergedDefaultColorConfigs = mergeColorConfigs([allDataEntities, defaultColors, defaultPortfolioConfigsAndColorsConfigs?.colors])
  const defaultDashboardConfigsHasChanged = !deepEqual(
    adjustConfigs({ colors: mergedDefaultColorConfigs, tabs: defaultPortfolioConfigsAndColorsConfigs?.tabs }),
    adjustConfigs({ colors: colorsConfigs, tabs: portfolioConfigs }))

  const dataColumnsOptionsGroups = filtersCategories?.map(category => {
    const sortedOptions = [...category.filtersNames]

    sortedOptions.sort()
    return { group: category.categoryName, options: sortedOptions }
  })

  dataColumnsOptionsGroups.unshift({ group: 'Count', options: [columnsNamesEnums.INDEX, columnsNamesEnums.COUNT] })

  const [filteredData, setFilteredData] = useState(generalDashboardData)

  const tabs = portfolioConfigs?.map(tabConfigs => tabConfigs.tab)

  const [currentTabIndex, setCurrentTabIndex] = useState(0)

  const headers = [
    {
      label: "Name",
      dataKey: columnsNamesEnums.BUILDING_NAME,
      cellType: null,
      valueTransformation: (row) => <Stack gap={2}>
        <img className="w-12 h-12 object-contain object-center" src={row[columnsNamesEnums.IMAGE_URL_VALUE]} />
        <span>
          {row[columnsNamesEnums.BUILDING_NAME]}
        </span>
      </Stack>,
      subValueTransformation: (row) => null,
      valueClassName: 'py-1',
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Portfolio',
      dataKey: columnsNamesEnums.PORTFOLIO,
      cellType: "",
      valueTransformation: (row) => row[columnsNamesEnums.PORTFOLIO],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Heat energy type',
      dataKey: columnsNamesEnums.HEAT_ENERGY_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.HEAT_ENERGY_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },

    {
      label: 'Main usage',
      dataKey: columnsNamesEnums.USE_TYPE,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.USE_TYPE],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'Energy consumption',
      dataKey: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
    {
      label: 'CO2 Emissions',
      dataKey: columnsNamesEnums.CO2_EMISSION,
      cellType: null,
      valueTransformation: (row) => row[columnsNamesEnums.CO2_EMISSION],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
      isInDefault: true,
    },
  ]

  const tabConfigs = portfolioConfigs[currentTabIndex]

  useEffect(() => {
    const tabConfigs = portfolioConfigs[currentTabIndex]
    if (!tabConfigs && portfolioConfigs?.length)
      setCurrentTabIndex(0)

  }, [portfolioConfigs])

  const allChartsConfigs = tabConfigs?.settings
  const setAllChartsConfigs = (tabAllChartsConfigs) => setPortfolioConfigs(portfoliosConfigs => {
    const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== currentTabIndex) return tabConfigs
      return { ...tabConfigs, settings: tabAllChartsConfigs }
    })
    return newPortfoliosConfigs
  })

  const allFiltersConfig = tabConfigs?.filters
  const setAllFiltersConfig = (tabAllFiltersConfigs) => setPortfolioConfigs(portfoliosConfigs => {
    const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== currentTabIndex) return tabConfigs
      return { ...tabConfigs, filters: tabAllFiltersConfigs }
    })
    return newPortfoliosConfigs
  })

  const selectedFiltersNames = useMemo(() => filtersCategories.map(category => {
    return { ...category, selectedFiltersNames: tabConfigs?.selectedFiltersNames?.filter(name => category.filtersNames.includes(name)) }
  }), [portfolioConfigs, currentTabIndex])

  const setSelectedFiltersNames = (tabSelectedFiltersNames) => setPortfolioConfigs(portfoliosConfigs => {
    const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== currentTabIndex) return tabConfigs
      return { ...tabConfigs, selectedFiltersNames: tabSelectedFiltersNames }
    })
    return newPortfoliosConfigs
  })


  const addFilterName = (name) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        const newFilterNames = tabConfigs.selectedFiltersNames
        newFilterNames.push(name)
        return { ...tabConfigs, selectedFiltersNames: newFilterNames }
      })
      return newPortfoliosConfigs
    })
  }

  const removeFilterName = (name) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        const newFilterNames = tabConfigs.selectedFiltersNames?.filter(filterName => filterName !== name)
        return { ...tabConfigs, selectedFiltersNames: newFilterNames }
      })
      return newPortfoliosConfigs
    })
  }

  const updateTabName = (newTabName, index) => setPortfolioConfigs(portfoliosConfigs => {
    const updatedPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
      if (i !== index) return tabConfigs
      return { ...tabConfigs, tab: newTabName }
    })
    return updatedPortfoliosConfigs
  })

  const addTab = () => {
    setCurrentTabIndex(currentTabIndex => portfolioConfigs?.length)
    setPortfolioConfigs(portfolioConfigs => {
      return [...portfolioConfigs, {
        filters: {},
        settings: [],
        selectedFiltersNames: [], tab: `New Tab`
      }]

    })
  }

  const deleteTab = (index) => {
    setPortfolioConfigs(portfolioConfigs => {
      return portfolioConfigs?.filter((_tabConfigs, i) => index !== i)
    })
    setCurrentTabIndex(currentTabIndex => index === 0 ? 0 : index - 1)

  }
  const scrollRef = useRef()

  const addChart = () => {
    setPortfolioConfigs(portfoliosConfigs => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
      const maxY = allChartsConfigs?.length
        ? Math.max(...allChartsConfigs?.map((l) => l.y + l.h))
        : -1;
      const maxId = allChartsConfigs?.length
        ? Math.max(...allChartsConfigs?.map((l) => l.id))
        : -1;
      const newChart = {
        ...defaultChart,
        y: maxY + 1,
        id: maxId + 1,
      };

      const newallChartsConfigs = [...allChartsConfigs, newChart]
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        return { ...tabConfigs, settings: newallChartsConfigs }
      })
      return newPortfoliosConfigs
    })

    scrollRef.current?.scrollIntoView({
      behavior: "smooth",
      block: 'center'
    });
  };

  const removeChart = (id) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
      const newallChartsConfigs = allChartsConfigs.filter((l) => l.id !== id)
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        return { ...tabConfigs, settings: newallChartsConfigs }
      })
      return newPortfoliosConfigs
    })
  };

  const updateChart = (id, key, value) => {
    setPortfolioConfigs(portfoliosConfigs => {
      const allChartsConfigs = portfoliosConfigs[currentTabIndex]?.settings
      const newallChartsConfigs = allChartsConfigs.map((l) => {
        if (l.id !== id) return l;
        else return { ...l, [key]: value };
      })
      const newPortfoliosConfigs = portfoliosConfigs?.map((tabConfigs, i) => {
        if (i !== currentTabIndex) return tabConfigs
        return { ...tabConfigs, settings: newallChartsConfigs }
      })
      return newPortfoliosConfigs
    })
  };

  const [openColorsModal, setOpenColorsModal] = useState(false)
  const [selectedColorEntity, setSelectedColorEntity] = useState()

  const [openColorPicker, setOpenColorPicker] = useState(false)

  const tableFilteredData = infoBoxesAndTableData.filter(tableDataRow => {
    return filteredData?.some(mainDataRow => mainDataRow[columnsNamesEnums.ID] === tableDataRow[columnsNamesEnums.ID])
  })

  if (allChartsConfigs)
    return (
      <LoadingOrEmptyWrapper showLoading={isLoading} height="400px">
        <article
          className="bg-white mt-8 px-4 pb-4 rounded-xl overflow-hidden"
        >
          <Header
            portfolioConfigs={portfolioConfigs}
            updateChart={updateChart}
            defaultDashboardConfigsHasChanged={defaultDashboardConfigsHasChanged}
            dashboardConfigsHasChanged={dashboardConfigsHasChanged}
            onResetDashboardConfigs={onResetDashboardConfigs}
            saveDashboardConfigs={saveDashboardConfigs}
            addChart={addChart}
            tabs={tabs}
            addTab={addTab}
            deleteTab={deleteTab}
            updateTabName={updateTabName}
            currentTabIndex={currentTabIndex}
            setCurrentTabIndex={setCurrentTabIndex}
            onOpenColors={() => {
              setSelectedColorEntity(undefined)
              setOpenColorsModal(openColorsModal => !openColorsModal)
            }}
          />
          {openColorsModal &&
            <Colors
              modalOpen={openColorsModal}
              setModalOpen={setOpenColorsModal}
              selectedColorEntity={selectedColorEntity}
              colorsConfigs={colorsConfigs}
              setColorsConfigs={setColorsConfigs}
            />
          }
          {openColorPicker &&
            <ColorPickerModal
              modalOpen={openColorPicker}
              setModalOpen={setOpenColorPicker}
              selectedColorEntity={selectedColorEntity}
              colorsConfigs={colorsConfigs}
              setColorsConfigs={setColorsConfigs}
            />
          }
          <PortfolioTab
            key={currentTabIndex + portfolioConfigs}
            filteredData={filteredData}
            setFilteredData={setFilteredData}
            dataColumns={dataColumns}
            dataColumnsOptionsGroups={dataColumnsOptionsGroups}
            categoricalColumns={categoricalColumns}
            numericalColumns={numericalColumns}
            generalDashboardData={generalDashboardData}
            allChartsConfigs={allChartsConfigs}
            setAllChartsConfigs={setAllChartsConfigs}
            updateChart={updateChart}
            removeChart={removeChart}
            allFiltersConfig={allFiltersConfig}
            setAllFiltersConfig={setAllFiltersConfig}
            selectedFiltersNames={selectedFiltersNames}
            setSelectedFiltersNames={setSelectedFiltersNames}
            removeFilterName={removeFilterName}
            addFilterName={addFilterName}
            scrollRef={scrollRef}
            infoBoxesData={infoBoxesAndTableData}
            colorsConfigs={colorsConfigs}
            setOpenColorPicker={setOpenColorPicker}
            setSelectedColorEntity={setSelectedColorEntity}
          />
          {/* <span ref={scrollRef}>Scroll ref</span> */}

        </article>
        <Paper className="mt-8">
          <h4 className="t-heading-m ">
            {tableFilteredData?.length} {t("AssetOverview.filters.buildings")}
          </h4>
          <Table
            className="relative mt-6"
            data={[...tableFilteredData]}
            headers={headers}
            idColumnName="id"
            columnsMinWidth="10rem"
            onRowClick={(row) => {
              window.open(`${window.location.origin}/details/${row[columnsNamesEnums.ID]}/buildingProfile`)
            }}
          />
        </Paper>

      </LoadingOrEmptyWrapper >
    );
};


const getColorEntityOrder = (a, b, aColor, bColor) => {
  if (aColor && !bColor) return -1
  else if (!aColor && bColor) return 1
  else if (a < b) return -1
  else return 1
}

export const Colors = ({ modalOpen, setModalOpen, colorsConfigs, setColorsConfigs }) => {
  const [updatedColorsConfigs, setUpdatedColorsConfigs] = useState(colorsConfigs)

  const colorCategories = Object.entries(updatedColorsConfigs)?.map(([categoryName, entitiesColors]) => {
    return { categoryName, entities: Object.keys(entitiesColors) }
  })
  const [activeCategoryName, setActiveCategoryName] = useState(Object.keys(updatedColorsConfigs)?.[0])

  let activeCategorySubcategoriesNames = Object.keys(updatedColorsConfigs?.[activeCategoryName])
  activeCategorySubcategoriesNames.sort((a, b) => {
    const aEntities = Object.keys(colorsConfigs?.[activeCategoryName]?.[a] || {})
    const bEntities = Object.keys(colorsConfigs?.[activeCategoryName]?.[b] || {})

    const aColoredEntities = Object.values(colorsConfigs?.[activeCategoryName]?.[a] || {}).filter(e => e)
    const bColoredEntities = Object.values(colorsConfigs?.[activeCategoryName]?.[b] || {}).filter(e => e)

    if (aColoredEntities?.length === bColoredEntities?.length)
      return aEntities?.length - bEntities?.length
    else return bColoredEntities?.length - aColoredEntities?.length
  })

  let activeCategoryEntities = colorCategories?.find(category => category.categoryName === activeCategoryName)?.entities

  activeCategoryEntities.sort((a, b) => {
    const aColor = colorsConfigs?.[activeCategoryName]?.[a]
    const bColor = colorsConfigs?.[activeCategoryName]?.[b]
    return getColorEntityOrder(a, b, aColor, bColor)
  }
  )

  useEffect(() => {
    setUpdatedColorsConfigs(colorsConfigs)
  }, [colorsConfigs])

  return (
    <CustomModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      width='50rem'
      height='90%'
      top={24}
      right={24}
      left='unset'
      style={{ transform: 'none' }}
    >
      <h3 className="t-heading-l">Color Catergories</h3>
      <Stack className="pt-4 flex-col  h-full gap-2 items-center justify-between w-full">
        <Grid className="w-full" container spacing={4}>
          {colorCategories?.map((colorCategory, index) => {
            const isActive = colorCategory.categoryName == activeCategoryName
            return <Grid item xs={4}>
              <Chip
                isOn={isActive}
                onClick={() => setActiveCategoryName(colorCategory.categoryName)}
              >
                <span>{colorCategory.categoryName}</span>
              </Chip>
            </Grid>

          })}

        </Grid>

        <Divider className='' />
        <div className="w-full  flex-grow  overflow-y-scroll ">
          <Stack className=" w-full  flex-col items-center gap-2 pr-2">
            {activeCategoryName === colorCategoriesEnums.COLUMNS && activeCategoryEntities?.map((entityName, index) => {
              return (
                <>
                  <ColorPickerItem
                    entityName={entityName}
                    color={updatedColorsConfigs?.[activeCategoryName]?.[entityName]}
                    setColor={(value) => {
                      setUpdatedColorsConfigs({
                        ...updatedColorsConfigs, [activeCategoryName]: {
                          ...updatedColorsConfigs?.[activeCategoryName],
                          [entityName]: value
                        }
                      })
                    }}
                  />
                  <Divider />
                </>
              )
            }
            )}
            {[colorCategoriesEnums.VALUES, colorCategoriesEnums.MIXED].includes(activeCategoryName)
              && activeCategorySubcategoriesNames?.map((subcategoryName, index) => {
                let entities = Object.keys(updatedColorsConfigs[activeCategoryName][subcategoryName])
                entities.sort((a, b) => {
                  const aColor = colorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[a]
                  const bColor = colorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[b]
                  return getColorEntityOrder(a, b, aColor, bColor)
                })

                const noOfColoredEntities = entities?.filter(e => updatedColorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[e])?.length
                return (
                  <Accordion className="w-full boder-t-0" sx={{
                    padding: 0,
                  }}>
                    <AccordionSummary
                      expandIcon={<Icon iconName={'ChevronDown'} />}
                      className="t-heading-s"
                    >
                      <Stack flexDirection={'column'} alignItems={'flex-start'} >
                        <h4>
                          {subcategoryName}
                        </h4>
                        <span className="t-numbers-xxs">{`${noOfColoredEntities ?? 0} color${noOfColoredEntities > 1 ? 's' : ''} set`}</span>
                      </Stack>
                    </AccordionSummary>
                    <AccordionDetails className="">
                      <Stack className="gap-2 flex-col pl-4">
                        {entities?.map(entityName => {
                          return (
                            <>
                              <ColorPickerItem
                                entityName={entityName}
                                color={updatedColorsConfigs?.[activeCategoryName]?.[subcategoryName]?.[entityName]}
                                setColor={(value) => {
                                  setUpdatedColorsConfigs({
                                    ...updatedColorsConfigs, [activeCategoryName]: {
                                      ...updatedColorsConfigs?.[activeCategoryName],
                                      [subcategoryName]: {
                                        ...updatedColorsConfigs?.[activeCategoryName][subcategoryName],
                                        [entityName]: value
                                      }
                                    }
                                  })
                                }}
                              />
                              <Divider />
                            </>

                          )
                        })}
                      </Stack>
                    </AccordionDetails>
                  </Accordion>
                )
              }
              )}
          </Stack>
        </div>
        <div className="h-10">
          <ButtonNew variant={'primary'} size={'md'} className={'w-20'}
            onClick={() => {
              setColorsConfigs(updatedColorsConfigs)
              setModalOpen(false)
            }
            }
          >Apply</ButtonNew>

        </div>

      </Stack >
    </CustomModal >

  )
}


const ColorPickerItem = ({ className, colorInputClassName, entityName, color, setColor }) => {
  const defaultColor = '#000000'
  const ref = useRef()
  return (
    <Stack className={`justify-between w-full  ${className} relative`}>
      <span className="t-heading-s">{entityName}</span>
      {(color) ?
        <ColorInput color={color} setColor={setColor} className={`w-24 border-0 rounded ${colorInputClassName}`} />
        : (
          <ButtonNew onClick={() => {
            ref.current.click()
            setColor(defaultColor)
          }} variant={'primary'} size={'sm'}>
            Choose color
          </ButtonNew>
        )}

      <ColorInput
        className="w-0 h-0 right-0 absolute opacity-0"
        inputRef={ref} color={color} setColor={setColor} />

    </Stack>
  )
}

export const ColorPickerModal = ({ modalOpen, setModalOpen, selectedColorEntity, colorsConfigs, setColorsConfigs }) => {
  const { t } = useTranslation()

  const categoryName = selectedColorEntity?.categoryName
  const subcategoryName = selectedColorEntity?.subcategoryName
  const entityName = selectedColorEntity?.entityName

  let currentColor
  if (categoryName === colorCategoriesEnums.COLUMNS)
    currentColor = colorsConfigs?.[categoryName]?.[entityName]
  else {
    currentColor = colorsConfigs?.[categoryName]?.[subcategoryName]?.[entityName]
  }
  const [newColor, setNewColor] = useState(currentColor)

  useEffect(() => {
    setNewColor(currentColor)
  }, [currentColor])

  return (
    <CustomModal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      width='40rem'
      height='30rem'
      top={'50%'}
      right={'50%'}
    >
      <h3 className="t-heading-l">Pick a color</h3>
      <Stack className="py-4  flex-col gap-2 items-center justify-between w-full h-full">
        <Breadcrumbs
          separator={<Icon iconName="ChevronRight" size="sm" />}
          aria-label="breadcrumb"
        >
          {[categoryName, subcategoryName, entityName].filter(e => e).map(e => <span>{e}</span>)}
        </Breadcrumbs>

        <ColorPickerItem
          colorInputClassName={'w-8 h-8 '}
          className={'!w-auto'}
          color={newColor}
          setColor={setNewColor}
        />
        <Stack gap={theme.spacing(8)}>
          <ButtonNew variant={'secondary'} size={'sm'}
            onClick={() => setModalOpen(false)}
          >{t('general.cancel')}
          </ButtonNew>
          <ButtonNew variant={'primary'} size={'sm'}
            onClick={() => {
              if (categoryName === colorCategoriesEnums.COLUMNS)
                setColorsConfigs({
                  ...colorsConfigs, [categoryName]: {
                    ...colorsConfigs?.[categoryName],
                    [entityName]: newColor
                  }
                })
              else
                setColorsConfigs({
                  ...colorsConfigs, [categoryName]: {
                    ...colorsConfigs?.[categoryName],
                    [subcategoryName]: {
                      ...colorsConfigs?.[categoryName]?.[subcategoryName],
                      [entityName]: newColor
                    }
                  }
                })
              setModalOpen(false)
            }}
          >{t('general.apply')}</ButtonNew>
        </Stack>


      </Stack >
    </CustomModal>

  )
}





export const PortfolioTab = ({
  filteredData,
  setFilteredData,
  dataColumns,
  dataColumnsOptionsGroups,
  categoricalColumns,
  numericalColumns,
  generalDashboardData,
  allChartsConfigs,
  setAllChartsConfigs,
  updateChart,
  removeChart,
  allFiltersConfig,
  setAllFiltersConfig,
  selectedFiltersNames,
  setSelectedFiltersNames,
  removeFilterName,
  addFilterName,
  scrollRef,
  infoBoxesData,
  colorsConfigs,
  setOpenColorPicker,
  setSelectedColorEntity
}) => {

  const setConfig = (filterName, value) => {
    setAllFiltersConfig({ ...allFiltersConfig, [filterName]: value })
  }

  const allAvailableFilters = useMemo(() => [
    {
      filterName: columnsNamesEnums.PORTFOLIO,
      mainDataKey: columnsNamesEnums.PORTFOLIO,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.PORTFOLIO,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.PORTFOLIO),
      },
    },
    {
      filterName: columnsNamesEnums.USE_TYPE,
      mainDataKey: columnsNamesEnums.USE_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.USE_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.USE_TYPE),
      },
    },
    {
      filterName: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.ENERGY_EFFICIENCY_CLASS),
      },
      config: allFiltersConfig,
      setConfig,
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_TYPE,
      config: allFiltersConfig,
      setConfig,
      props: {
        width: "100%",
        height: '4.8rem',
        label: columnsNamesEnums.HEAT_ENERGY_TYPE,
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.HEAT_ENERGY_TYPE),
      },
    },
    {
      filterName: columnsNamesEnums.PREMISE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.PREMISE,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.PREMISE,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.PREMISE], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.FOOTPRINT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.FOOTPRINT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.FOOTPRINT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.FOOTPRINT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.GROSS_AREA_WITH_BASEMENT], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.GROSS_LETTABLE_AREA_WIGHOUT_BASEMENT], defaultMin: 0 })
      },
    },

    {
      filterName: columnsNamesEnums.FLOOR_AMOUMT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.FLOOR_AMOUMT,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.FLOOR_AMOUMT,
        step: 1,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.FLOOR_AMOUMT], defaultMin: 0
        })
      },
    },

    {
      filterName: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsNamesEnums.YEAR_OF_CONSTRUCTION,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.YEAR_OF_CONSTRUCTION],
          defaultMin: 1900
        }),
      },
    },

    {
      filterName: columnsNamesEnums.STRANDING_YEAR,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.STRANDING_YEAR,
      config: allFiltersConfig,
      setConfig,
      props: {
        step: 1,
        className: 'w-full',
        label: columnsNamesEnums.STRANDING_YEAR,
        ...findDataRange({
          data: generalDashboardData, dataKeys: [columnsNamesEnums.STRANDING_YEAR],
          defaultMin: 1900

        })

      },
    },

    {
      filterName: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION], defaultMin: 0 })

      },
    },

    {
      filterName: columnsNamesEnums.CO2_EMISSION,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.CO2_EMISSION,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.CO2_EMISSION,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.CO2_EMISSION], defaultMin: 0 })

      },
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.HEAT_ENERGY_CONSUMPTION_EPC_KWH_M2_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_A], defaultMin: 0 })
      },
    },
    {
      filterName: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      mainDataKey: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
      config: allFiltersConfig,
      setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.ELECTRICITY_DEMAND_KWH_M2A], defaultMin: 0 })
      },
    },
    // ////////////////////////////////////////////////////////////////////////////////////

    {
      filterName: columnsNamesEnums.HERITAGE_PROTECTED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.HERITAGE_PROTECTED, config: allFiltersConfig, setConfig,
      props: {
        className: 'w-full',
        label: columnsNamesEnums.HERITAGE_PROTECTED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.HERITAGE_PROTECTED),
      },
    },
    {
      filterName: columnsNamesEnums.BUILDING_TYPE_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_TYPE_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_TYPE_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_TYPE_ID),
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_TYPE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_TYPE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_TYPE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_TYPE),
      }
    },
    {
      filterName: columnsNamesEnums.PRE_FABRICATED,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.PRE_FABRICATED, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.PRE_FABRICATED,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.PRE_FABRICATED),
      }
    },
    {
      filterName: columnsNamesEnums.LOGGIA,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.LOGGIA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.LOGGIA,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.LOGGIA),
      }
    },
    {
      filterName: columnsNamesEnums.BALCONY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BALCONY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BALCONY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BALCONY),
      }
    },
    {
      filterName: columnsNamesEnums.CEILING_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.CEILING_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.CEILING_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.CEILING_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.FACADE_AREA,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.FACADE_AREA, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.FACADE_AREA,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.FACADE_AREA], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.NET_AREA_WITH_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.NET_AREA_WITH_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NET_AREA_WITH_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.NET_AREA_WITH_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.NET_LETTABEL_AREA_WITHOUT_BASEMENT], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.EPC_CONSUMPTION_KWH_M2A], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.DISTRICT_HEATING_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.DISTRICT_HEATING_AVAILABLE),
      }
    },
    {
      filterName: columnsNamesEnums.NATURAL_GAS_AVAILABLE,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.NATURAL_GAS_AVAILABLE, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.NATURAL_GAS_AVAILABLE,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.NATURAL_GAS_AVAILABLE),
      }
    },
    {
      filterName: columnsNamesEnums.BOUGHT_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.BOUGHT_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BOUGHT_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.BOUGHT_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.SOLD_ON,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.SOLD_ON, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.SOLD_ON,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.SOLD_ON], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.RENTAL_UNITS,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER, mainDataKey: columnsNamesEnums.RENTAL_UNITS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.RENTAL_UNITS,
        ...findDataRange({ data: generalDashboardData, dataKeys: [columnsNamesEnums.RENTAL_UNITS], defaultMin: 0 })
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_ID,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_ID, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_ID,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_ID),
      }
    },
    {
      filterName: columnsNamesEnums.BUILDING_NAME,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.BUILDING_NAME, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.BUILDING_NAME,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.BUILDING_NAME),
      }
    },
    {
      filterName: columnsNamesEnums.ZIP,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.ZIP, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.ZIP,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.ZIP),
      }
    },
    {
      filterName: columnsNamesEnums.CITY,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.CITY, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.CITY,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.CITY),
      }
    },
    {
      filterName: columnsNamesEnums.MAIN_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.MAIN_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.MAIN_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.MAIN_DISTRICT),
      }
    },
    {
      filterName: columnsNamesEnums.SUB_DISTRICT,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.SUB_DISTRICT, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.SUB_DISTRICT,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.SUB_DISTRICT),
      }
    },
    {
      filterName: columnsNamesEnums.FULL_ADDRESS,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.FULL_ADDRESS, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.FULL_ADDRESS,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.FULL_ADDRESS),
      }
    },
    {
      filterName: columnsNamesEnums.STREET,
      filterType: FilterTypes.DROPDOWN_MULTI, mainDataKey: columnsNamesEnums.STREET, config: allFiltersConfig, setConfig, props: {
        className: 'w-full',
        label: columnsNamesEnums.STREET,
        width: "100%",
        height: '4.8rem',
        options: getUniqueValuesFromJson(generalDashboardData, columnsNamesEnums.STREET),
      }
    }

  ], [generalDashboardData, allFiltersConfig])

  const selectedFilters = useMemo(() => {
    return selectedFiltersNames.map(filterCategory => {
      const filters = filterCategory?.filtersNames?.map(name => allAvailableFilters?.find(filter => name === filter?.filterName))
      return { ...filterCategory, filters }
    })
  }, [selectedFiltersNames, allFiltersConfig, generalDashboardData])

  const dynamicChartsRowHeight = 50;

  const sortOptions = []
  const allSettings = (updateChart, config) => [
    {
      filterName: FiltersNames.TITLE,
      filterLabel: 'Chart title',
      filterType: FilterTypes.INPUT,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        className: "block w-3/4 ",
        label: "Title",
        placeholder: "Title",
        height: "4rem"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.TITLE),
      isInDiagramsPart: false,
    },
    {
      mainDataKey: 'dataPoint',
      useAsDataFilter: false,
      filterName: FiltersNames.DATAPOINT,
      filterLabel: 'Data point',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Data point",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        width: "75%",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.DATA_POINT),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.COMPOSED].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.X_AXIS,
      filterLabel: 'X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "x-axis",
        options: [columnsNamesEnums.INDEX, ...dataColumns],
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => ([chartTypesEnums.BAR, chartTypesEnums.AREA, chartTypesEnums.LINE, chartTypesEnums.HEATMAP].includes(config.chartType)) &&
        isRelevantOption(config.chartType, settingsOptions.X,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_X_AXIS,
      filterLabel: 'Invert X Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert X Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_X_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.Y_AXIS,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "y-axis",
        options: [columnsNamesEnums.INDEX, ...dataColumns],
        displayOptions: [columnsNamesEnums.INDEX, ...dataColumns,],
        optionsGroups: dataColumnsOptionsGroups,
        className: "w-3/4"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.Y,),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.INVERT_Y_AXIS,
      filterLabel: 'Invert Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Invert Y Axis",
        options: [0, 1],
        displayOptions: ["No", "Yes"],
        className: "block w-3/4 ",
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.INVERT_Y_AXIS),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.LEGEND_POSITION,
      filterLabel: 'Lengeds Position',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Lengeds position",
        options: ['bottom', 'right'],
        displayOptions: ['Bottom', 'Right'],
        width: "75%"
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.LEGENDS_POSITION),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.SORT_VALUE,
      filterLabel: 'Sort by',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Sort by",
        options: getSortOptions({ chartType: config.chartType })?.options,
        displayOptions: getSortOptions({ chartType: config.chartType })?.displayOptions,
        className: "block w-3/4 "
      },
      visible: () => {
        return isRelevantOption(config.chartType, settingsOptions.SORT_BY) &&
          !(config.chartType === chartTypesEnums.COMPOSED && numericalColumns?.includes(config?.[FiltersNames.X_AXIS]))
      },
      isInDiagramsPart: false,
    },
    {
      filterName: settingsOptions.COLOR_BAR_RANGE,
      filterType: FilterTypes.NUMERIC_RANGE_PICKER,
      filterLabel: 'Color bar range',
      mainDataKey: columnsNamesEnums.PREMISE,
      adjustConfig: (config) => config,
      setConfig: (updateChart) => updateChart,
      props: {
        label: "Color bar range",
        className: "block w-3/4 ",
        changeByTyping: true,
        ...findDataRange({ data: filteredData, dataKeys: [config?.dataPoint], defaultMin: 0 })
      },
      visible: () => isRelevantOption(config.chartType, settingsOptions.COLOR_BAR_RANGE),
      isInDiagramsPart: false,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_DATA_KEY,
      filterLabel: 'Y Axis',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "y-axis",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.QUANTITY, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIAGRAM_TYPE,
      filterLabel: 'Diagram type',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Diagram type",
        options: Object.values(diagramTypes),
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIAGRAM_TYPE, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_PARTITIONS,
      filterLabel: 'Partitions',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Partitions",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.PARTITIONS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_STACKS,
      filterLabel: 'Stacks',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Stacks",
        options: dataColumns,
        optionsGroups: dataColumnsOptionsGroups,
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.STACKS, diagramIndex),
      isInDiagramsPart: true,
    },
    {
      filterName: FiltersNames.DIAGRAMS_DIRECTION,
      filterLabel: 'Direction',
      filterType: FilterTypes.DROPDOWN_SINGLE,
      adjustConfig: (config) => config,
      adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
      props: {
        label: "Direction",
        options: ["horizontal", "vertical"],
        displayOptions: ["Horizontal", "Vertical"],
        className: "block w-3/4 ",
      },
      visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.DIRECTION, diagramIndex),
      isInDiagramsPart: true,
    },
    // {
    //   filterLabel: 'Color',
    //   filterName: FiltersNames.DIAGRAMS_COLOR,
    //   filterType: FilterTypes.COLOR_INPUT,
    //   adjustConfig: (config) => config,
    //   adjustSetConfigFn: (index) => (filterName, value) => handleConfigChange(index, config.diagrams, filterName, value, updateChart),
    //   props: {
    //     className: "block w-3/4 ",
    //   },
    //   visible: (diagramIndex) => isRelevantOption(config.chartType, settingsOptions.COLOR, diagramIndex),
    //   isInDiagramsPart: true,
    // },
  ]

  const useGetSpecificData = (_config) => {
    return { specificData: [], isLoading: false, isError: false }
  }

  const getDataTransformatorMemoDependencyArray = useCallback(({ config }) => {
    const { dataPoint, chartType, diagrams, xAxis, yAxis } = config

    return JSON.stringify([dataPoint, chartType, diagrams, xAxis, yAxis])
  }, [])

  const dataTransformator = useCallback((data, { dataPoint, chartType, diagrams, xAxis, yAxis }) => {
    let output = []
    const { partitions, stacks } = diagrams?.[0]
    if ([chartTypesEnums.DOUGHNUT, chartTypesEnums.PIE].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = flattenData || []
    }

    else if ([chartTypesEnums.AREA, chartTypesEnums.BAR, chartTypesEnums.LINE, chartTypesEnums.KPI].includes(chartType)) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (partitions) filteredRow[partitions] = row[partitions];
        if (stacks) filteredRow[stacks] = row[stacks];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      output = flattenData || []
    }

    else if (chartType === chartTypesEnums.HEATMAP) {

      const flattenData = data?.map((row) => {
        const filteredRow = {};
        if (yAxis) filteredRow[yAxis] = row[yAxis];
        if (xAxis) filteredRow[xAxis] = row[xAxis];
        return {
          ...filteredRow,
          dataPoint,
          [dataPoint]: row[dataPoint],
        };
      });
      const xLabels = data
        ?.map((row) => row[xAxis])
        ?.filter(distinctFilter);

      const yLabels = data
        ?.map((row) => row[yAxis])
        ?.filter(distinctFilter);

      output = { flattenData: flattenData || [], xLabels, yLabels }
    }

    return output

  }, [])


  const [clickedAssets, setClickedAssets] = useState([])
  const [triggerFlag, setTriggerFlag] = useState(false)

  const eventHandlers = useCallback((point, config) => {
    const { dataPoint, chartType, partitions, stacks, xDataKey, yDataKey } = config;

    if (chartType === chartTypesEnums.MINI_MAP) {
      return {

        click: (point, viewportPosition) => {
          setClickedAssets(clickedAssets => {
            const newClickedAssetId = point?.[columnsNamesEnums.ID]
            if (clickedAssets?.map(asset => asset?.id)?.includes(newClickedAssetId))
              return clickedAssets.filter(asset => asset?.id !== newClickedAssetId)
            else return [...clickedAssets, { id: newClickedAssetId, assetPosition: viewportPosition }]
          })
          setTriggerFlag(triggerFlag => !triggerFlag)
        },
        mouseover: (point) => {
          setHoveredItem({ point, config })
        },
        mouseout: (point) => {
          setHoveredItem(undefined)
        }
      }
    }

  }, [])

  const getUnit = useCallback(({ quantity }) => columnsUnits?.[quantity], [])

  const getColorEntity = ({ quantity, chartType, diagrams }) => {
    let categoryName, subcategoryName
    let entityName = (typeof quantity === 'string') ? quantity || valuesEnums.NO_DATA : quantity ?? valuesEnums.NO_DATA

    if (!chartType || !diagrams) return { categoryName, subcategoryName, entityName }

    let stacks, partitions, isStacked, isMixed
    if (diagrams?.length) {
      stacks = diagrams[0]?.stacks
      partitions = diagrams[0]?.partitions
      isMixed = partitions && stacks
      isStacked = partitions || stacks
    }

    if ([chartTypesEnums.COMPOSED, chartTypesEnums.HEATMAP, chartTypesEnums.HISTOGRAM].includes(chartType)) {
      categoryName = colorCategoriesEnums.COLUMNS
    }

    if ([chartTypesEnums.PIE, chartTypesEnums.DOUGHNUT, chartTypesEnums.LINE, chartTypesEnums.AREA].includes(chartType)) {
      categoryName = partitions ? colorCategoriesEnums.VALUES : colorCategoriesEnums.COLUMNS
      subcategoryName = partitions ? partitions : ''
    }

    if ([chartTypesEnums.BAR, chartTypesEnums.KPI].includes(chartType)) {
      if (isMixed) {
        categoryName = colorCategoriesEnums.MIXED
        subcategoryName = `(${stacks})-(${partitions})`
      } else if (isStacked) {
        categoryName = colorCategoriesEnums.VALUES
        subcategoryName = stacks ?? partitions
      } else {
        categoryName = colorCategoriesEnums.COLUMNS
        subcategoryName = ''
      }
    }
    return { categoryName, subcategoryName, entityName }
  }

  const getColor = useCallback(({ quantity, chartType, diagrams }) => {
    const { categoryName, subcategoryName, entityName } = getColorEntity({ quantity, chartType, diagrams })

    if (categoryName === colorCategoriesEnums.COLUMNS)
      return colorsConfigs?.[categoryName]?.[entityName]
    return colorsConfigs?.[categoryName]?.[subcategoryName]?.[entityName]

  }, [colorsConfigs])

  const onLegendSelect = useCallback(({ params, config }) => {
    const { chartType, diagrams } = config
    const { categoryName, subcategoryName, entityName } = getColorEntity({ quantity: params?.name, chartType, diagrams })
    setSelectedColorEntity({ categoryName, subcategoryName, entityName })
    setOpenColorPicker(true)
  }, [])

  const [hoveredItem, setHoveredItem] = useState()

  const onHover = useCallback(({ params, config, point }) => {
    // console.log(params, config, point)
    setHoveredItem({ params, config, point })
  }, [])

  const onMouseOut = useCallback(() => {
    setHoveredItem()
  }, [])

  const getHoveredItemRelatedItems = useMemo(() => {
    if (!hoveredItem) return
    const { params, config, point } = hoveredItem
    const { chartType, diagrams, xAxis, yAxis, } = config
    const xIsNumeric = filteredData?.some(row => isNumeric(row[xAxis]))
    let stacks, partitions, isStacked, isMixed
    if (diagrams?.length) {
      stacks = diagrams[0]?.stacks
      partitions = diagrams[0]?.partitions
      isMixed = partitions && stacks
      isStacked = partitions || stacks
    }

    if (chartType === chartTypesEnums.MINI_MAP || (chartType === chartTypesEnums.COMPOSED && xIsNumeric)) {
      return [point]
    }
    if (chartType === chartTypesEnums.HISTOGRAM) {
      const { rangeString, index, dataPoint } = point
      const valueRange = rangeString?.split('-')?.map(numString => parseFloat(numString))
      const [min, max] = valueRange
      return filteredData.filter((row) => {
        const value = row[dataPoint]
        return (value > min || (value === min && index === 0)) && value <= max
      })
    }
    return filteredData?.filter(row => areItemsSame({ item1: point, item2: row, chartType, xAxis, yAxis, diagrams }))
  }, [hoveredItem]
  )

  const isItemHovered = useCallback(({ row, chartType, xAxis, yAxis, diagrams }) => {
    let stacks, partitions, isStacked, isMixed
    const xIsNumeric = filteredData?.some(row => isNumeric(row[xAxis]))
    if (diagrams?.length) {
      stacks = diagrams[0]?.stacks
      partitions = diagrams[0]?.partitions
      isMixed = partitions && stacks
      isStacked = partitions || stacks
    }
    const relatedItems = getHoveredItemRelatedItems

    if (chartType === chartTypesEnums.MINI_MAP && hoveredItem?.config?.chartType === chartTypesEnums.MINI_MAP)
      return true

    // Hardcoded value
    else if (chartType === chartTypesEnums.MINI_MAP || (chartType === chartTypesEnums.COMPOSED && xIsNumeric)) {
      return relatedItems?.some(item => item?.ID === row?.ID)
    }
    else if (chartType === chartTypesEnums.HISTOGRAM) {
      const { dataPoint, rangeString, index } = row
      const interval = rangeString?.split('-')?.map(numString => parseFloat(numString))
      const [min, max] = interval
      return relatedItems?.some(item => {
        const value = item[dataPoint]
        return (value > min || (value === min && index === 0)) && value <= max
      })
    }
    else {
      // console.log('relatedItems', relatedItems, 'row', row, 'config', config)
      // console.log('xAxis', xAxis, 'yAxis', yAxis, 'stacks', stacks, 'partitions', partitions)
      return relatedItems?.some(item => areItemsSame({ item1: item, item2: row, chartType, xAxis, yAxis, diagrams }))
    }
  }, [hoveredItem])


  return (
    <>
      <Filters
        filterCategories={selectedFilters}
        setFilteredData={setFilteredData}
        data={generalDashboardData}
        // setFilterCategories={setSelectedFiltersNames}
        setConfig={setConfig}
        removeFilterName={removeFilterName}
        addFilterName={addFilterName}
      />

      {clickedAssets?.map((clickedAsset, index) => {
        const id = clickedAsset?.id
        const selectedBuilding = infoBoxesData?.find(building => building?.[columnsNamesEnums.ID] === id)
        return (
          <AssetCardModal key={id} triggerFlag={`${triggerFlag} ${id}`} onCardClick={() => {
            window.open(`${window.location.origin}/details/${id}/buildingProfile`)
          }}
            onClose={() => setClickedAssets(clickedAssets => clickedAssets.filter(asset => asset?.id !== id))}
            assetCardPosition={clickedAsset?.assetPosition}
            building={selectedBuilding}
          />
        )
      })}

      <DynamicDashboard
        allChartsConfigs={allChartsConfigs}
        setAllChartsConfigs={setAllChartsConfigs}
        dynamicChartsRowHeight={dynamicChartsRowHeight}
        generalDashboardData={filteredData}
        allFilters={() => []}
        allSettings={allSettings}
        specificDataGetter={useGetSpecificData}
        dataTransformator={dataTransformator}
        getDataTransformatorMemoDependencyArray={getDataTransformatorMemoDependencyArray}
        removeChart={removeChart}
        updateChart={updateChart}
        eventHandlers={eventHandlers}
        scrollRef={scrollRef}
        getUnit={getUnit}
        getColor={getColor}
        onLegendSelect={onLegendSelect}
        onHover={onHover}
        onMouseOut={onMouseOut}
        isItemHovered={isItemHovered}
        somethingIsHovered={!!hoveredItem}
      />
    </>
  );
};
