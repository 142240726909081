import React, { useRef } from "react";
import { exportComponentAsJPEG } from "react-component-export-image";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { IsAllowed } from "../acl";
import {
  useGetEnergyMix,
  useGetPerformanceIndicator,
} from "../api/hooks/allHooks";
import { Dashboard } from "../components/EnergyPerformance/Dashboard";
import TableWithHeader from "../components/TableWithHeader";
import { BulletChart } from "../components/index";
import { EnergyAndCarbonPerformance } from "../components/Modernization/EnergyAndCarbonPerformance";
import { StrandingChart } from "../components/Modernization/StrandingChart";
import { Stack } from "@mui/material";
import { theme } from "../styles/theme";
import { DashboardNew } from "../components/EnergyPerformance/DashboardNew";
import { MyMap } from "../components/map/Map";
import { DashboardNewMultiTab } from "../components/EnergyPerformance/DashboardNewMultiTab";

export const EnergyPerformanceNewMultiTab = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();

  const { data, isLoading: pIIsLoading } = useGetPerformanceIndicator(
    projectId
  );
  const energyIndicator = (data || []).find(
    (indicator) => indicator.type === "energy performance indicator"
  );
  const carbonIndicator = (data || []).find(
    (indicator) => indicator.type === "carbon performance indicator"
  );

  const { data: energyMix, isLoading: eMIsLoading } = useGetEnergyMix(
    projectId
  );

  const tableRef = useRef();


  const tableColumns = [
    {
      label: t("EnergyPerformance.table.columns.Utility mix"),
      dataKey: "utility_mix",
      cellType: null,
      valueTransformation: (row) => row["utility_mix"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
    {
      label: t("EnergyPerformance.table.columns.Type"),
      dataKey: "type",
      cellType: null,
      valueTransformation: (row) => row["type"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
    {
      label: t("EnergyPerformance.table.columns.Source"),
      dataKey: "source",
      cellType: null,
      valueTransformation: (row) => row["source"],
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
    {
      label: t("EnergyPerformance.table.columns.Price"),
      dataKey: "price",
      cellType: null,
      valueTransformation: (row) => `${row["price"]} €/kWh`,
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
    {
      label: t("EnergyPerformance.table.columns.CO₂ emission"),
      dataKey: "carbon_dioxide_emission",
      cellType: null,
      valueTransformation: (row) =>
        `${row["carbon_dioxide_emission"]} kg CO₂/kWh`,
      subValueTransformation: (row) => null,
      downloadZipFile: (row) => null,
      href: (row) => null,
    },
  ];
  return (
    <>
      <Helmet>
        <title>Energy & CO₂ | Leaftech</title>
      </Helmet>

      <Wrapper>
        <Stack spacing={theme.spacing(4)} alignItems="start" className="mb-8">
          <EnergyAndCarbonPerformance
            // className="w-1/2 "
            className="flex-1"
            projectId={projectId}
          />
          <StrandingChart
            className="w-[70rem] h-[45.5rem] "
            chartHeight={280}
            legendsWidth="450px"
            projectId={projectId}
            selectedMeaures={[]}
          />
        </Stack>

        <DashboardNewMultiTab />

        <div ref={tableRef} className="relative min-h-[15rem]">
          <TableWithHeader
            className="mt-8"
            header={t("EnergyPerformance.table.title")}
            action={() => exportComponentAsJPEG(tableRef)}
            actionText={` ${t("EnergyPerformance.table.downloadText")}`}
            actionIcon="Download"
            buttonWidth="17.3rem"
            tableData={energyMix || []}
            tableHeaders={tableColumns}
            isLoading={eMIsLoading}
            idColumnName="id"
            hasActionAccess={IsAllowed("ENERGY_PERFORMANCE.DOWNLOAD_FILES")}
            actionIsDisabled={!energyMix || energyMix?.length === 0}
          ></TableWithHeader>
        </div>
      </Wrapper>
    </>
  );
};
const Wrapper = styled("section")(() => ({
  ".bullet-chart": {
    // marginBottom: "1.6rem",
  },
  ".bar-chart-btns": {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "2rem",
    marginTop: "2rem",
    flexWrap: "wrap",
  },
  ".barcharts-container": {
    display: "flex",
    justifyContent: "space-between",
  },
  ".pie-charts": {
    display: "flex",
    justifyContent: "space-between",
  },
  ".variable": {
    width: "23%",
    padding: "1rem",
    marginBottom: "1rem",
    textAlign: "center",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "1rem",
  },
  ".variable.false": {
    border: "1px solid gray",
  },
  ".variable.true": {
    border: "1px solid blue",
  },
  // Barchart:
  ".barchart-container": {
    background: "transparent",
    border: "16px solid var(--clr-white)",
    borderTop: "none",
  },
  ".header, .title-container, .config-container, .units-container, .variable-date-container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ".header": {
    // marginTop: "1.1rem",
  },
  ".config-container": {
    // marginTop: "3.1rem",
  },
  ".title-container": {
    h2: {
      marginRight: "2.4rem",
    },
  },
  ".checkbox": {
    marginRight: "1.6rem",
    textTransform: "capitalize",
  },
  ".download-information": {
    marginLeft: "0.6rem",
  },
  ".label": {
    marginLeft: "0.8rem",
    cursor: "pointer",
  },
  ".units-container": {
    gap: "1.6rem",
  },
  ".variable-date-container": {
    gap: "1.6rem",
  },
  ".stack-barchart": {
    // marginTop: "1.6rem",
    display: "flex",
    position: "relative",
  },
  ".legends-container": {
    marginTop: "0rem",
    // width: "38rem",
    // minWidth: "30rem",
    paddingLeft: "1.6rem",
  },
  ".legend": {
    padding: "1.4rem 0",
    borderBottom: "1px solid var(--clr-gray-100)",
  },
  ".add-chart-btn": {
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ".add-sign": {
    marginRight: "1rem",
  },
  ".remove-sign": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ".home__icon path": {
      stroke: "var(--clr-gray-800)",
    },
    marginRight: "1rem",
  },

  ".add-new-chart-container": {
    marginTop: "1.6rem",
    display: "flex",
    gap: "1.6rem",
    justifyContent: "center",
  },
}));
