import { getEnergyClassItem } from "../functions/AssetOverview";

export const EnergyEfficiencyClass = ({ energyClass, energyConsumption, className, size, style }) => {
  const energyClassItem = getEnergyClassItem({ energyClass, energyConsumption })
  if (!energyClassItem) return

  const bgColor = energyClassItem?.bgColor;
  const textColor = energyClassItem?.textColor;
  const energyLabel = energyClassItem?.rating;
  return (
    <div
      className={className}
      style={{
        color: textColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: bgColor,
        borderRadius: "5px",
        textTransform: "uppercase",
        textAlign: 'center',
        ...style
      }}
    >
      <span className={`${size === "sm" ? "t-heading-s" : "t-heading-l2"}`}>
        {energyLabel}
      </span>
    </div>
  );
};



export const EnergyEfficiencyText = ({ energyClass, energyConsumption, className, size }) => {
  const energyClassItem = getEnergyClassItem({ energyClass, energyConsumption })
  if (!energyClassItem) return

  const textColor = energyClassItem?.bgColor;
  const energyLabel = energyClassItem?.rating;
  return (
    <span className={`${size === "sm" ? "t-heading-s" : size === 'md' ? 't-heading-l' : "t-heading-l2"} ${className}`}
      style={{ color: textColor }}>
      {energyLabel}
    </span>
  );
};
