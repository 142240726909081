import React, { useEffect, useState } from "react";
import { CustomModal } from "../CustomModal";
import { AssetCard } from "./AssetCard";
import Draggable from "react-draggable";
import { Stack } from "@mui/material";
import { Icon } from "../Icon";
import { AssetDetailedCard } from "../BuildingProfile/AssetDetailedCard";
import { columnsNamesEnums } from "../../enums/AssetOverview/portfolio";
import { useTranslation } from "react-i18next";

export const AssetCardModal = ({ building, assetCardPosition, triggerFlag, onCardClick, onClose }) => {
    const [modalOpen, setModalOpen] = useState(false)

    useEffect(() => {
        if (building) setModalOpen(true)
    }, [triggerFlag])

    const assetCardConfig = [
        [
            { label: "ASSET NAME", dataKey: columnsNamesEnums.BUILDING_NAME },
            { label: "TYPE", dataKey: columnsNamesEnums.USE_TYPE },
            { label: "ADDRESS", dataKey: columnsNamesEnums.FULL_ADDRESS },
            { label: "GROSS ASSET SIZE", dataKey: columnsNamesEnums.GROSS_AREA_WITH_BASEMENT },
        ],
        [
            { label: "YEAR OF CONSTRUCTION", dataKey: columnsNamesEnums.YEAR_OF_CONSTRUCTION },
            { label: "STRANDING YEAR", dataKey: columnsNamesEnums.STRANDING_YEAR },
            { label: "EPC CLASS", dataKey: columnsNamesEnums.ENERGY_EFFICIENCY_CLASS },
            { label: "CO2 EMISSIONS", dataKey: columnsNamesEnums.CO2_EMISSION },
            { label: "ENERGY CONSUMPTION", dataKey: columnsNamesEnums.TOTAL_ENERGY_CONSUMPTION },
        ]
    ];
    return (
        <Draggable >
            <CustomModal
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                onClose={undefined}
                width={500}
                height={0}
                closeIcon={false}
                padding={0}
                style={{
                    position: 'fixed',
                    top: 50,
                    top: assetCardPosition?.y || 50,
                    left: assetCardPosition?.x || 50,
                    bottom: 'unset',
                    transform: 'none',
                    height: 'fit-content',
                    marginRight: '1.6rem'
                }}
                slotProps={{
                    backdrop: {
                        sx: {
                            display: 'none',
                        },
                    },
                    root: {
                        sx: {
                            zIndex: 2000,
                            right: 0,
                            top: '60px',
                            bottom: 'unset',
                            left: 'unset'
                        }
                    }
                }}
                disableScrollLock
                hideBackdrop
                disableBackdropClick
                disableEnforceFocus
            >
                <Stack flexDirection={'row-reverse'} className="bg-blue-50 h-6 pr-2 cursor-grab">
                    <Icon iconName={'Close'} size={'sm'} color={'var(--clr-secondary-blue-500)'}
                        onClick={() => {
                            setModalOpen(false)
                            onClose()
                        }}
                    />
                </Stack>
                <AssetDetailedCard
                    className={'w-full text-left p-3 cursor-pointer hover:scale-[1.003] hover:shadow'}
                    imageClassName={'h-50 w-full  object-cover'}
                    building={building}
                    config={assetCardConfig}
                    upload={false}
                    imgPath={building?.[columnsNamesEnums.IMAGE_URL_VALUE]}
                    // name={t("BuildingProfile.card.title")}
                    buildingType={building?.[columnsNamesEnums.USE_TYPE]}
                    onClick={onCardClick}
                />
            </CustomModal>
        </Draggable>
    )
}
