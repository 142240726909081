import { settingsOptions } from "../utils/dynamicDashboard";
import { chartTypesEnums } from "./dynamicDashboard";


export const getColumnDisplayName = ({ colName }) => {
    return dataMapping[colName]
}


export const FiltersNames = Object.freeze({
    SELECTED_SENSORS: 'selectedUtilities',
    SELECTED_SCENARIOS: 'selectedScenarios',
    TIMEFRAME: 'timeframe',
    SELECTED_DAYS_OF_WEEK: 'selectedDaysOfWeek',
    SELECTED_HOURS_OF_DAY: 'selectedHoursOfDay',
    DATE_RANGE: 'dateRange',
    START_DATE: 'startDate',
    END_DATE: 'endDate',
    DIAGRAMS: 'diagrams',
    X_AXIS: 'xAxis',
    Y_AXIS: 'yAxis',
    DATAPOINT: 'dataPoint',
    TITLE: 'title',
    SORT_VALUE: 'sortValue',
    LEGEND_POSITION: 'legendsPosition',
    INVERT_X_AXIS: 'invertXAxis',
    INVERT_Y_AXIS: 'invertYAxis',

    DIAGRAMS_COLOR: 'color',
    DIAGRAMS_DIRECTION: 'direction',
    DIAGRAMS_STACKS: 'stacks',
    DIAGRAMS_PARTITIONS: 'partitions',
    DIAGRAMS_DIAGRAM_TYPE: 'diagramType',
    DIAGRAMS_DIAGRAM_DATA_KEY: 'dataKey',
})

const relevantConfigOptions = {
    [chartTypesEnums.AREA]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.HEIGHT_100,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.BAR]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.STACKS,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.PIE]: [
        settingsOptions.TITLE,
        settingsOptions.PARTITIONS, settingsOptions.STACKS,
        settingsOptions.DATA_POINT,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
    ],
    [chartTypesEnums.DOUGHNUT]: [
        settingsOptions.TITLE,
        settingsOptions.PARTITIONS, settingsOptions.STACKS,
        settingsOptions.DATA_POINT,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
    ],
    [chartTypesEnums.LINE]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.X,
        settingsOptions.PARTITIONS,
        settingsOptions.SORT_BY,
        settingsOptions.DIRECTION,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.COMPOSED]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.X,
        settingsOptions.QUANTITY,
        settingsOptions.DIAGRAM_TYPE,
        settingsOptions.COLOR,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.KPI]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.PARTITIONS, settingsOptions.STACKS, settingsOptions.DIRECTION],
    [chartTypesEnums.HISTOGRAM]: [
        settingsOptions.TITLE,
        settingsOptions.QUANTITY, settingsOptions.COLOR,
        settingsOptions.DATA_POINT,
        settingsOptions.LEGENDS_POSITION,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
    [chartTypesEnums.HEATMAP]: [
        settingsOptions.TITLE,
        settingsOptions.DATA_POINT,
        settingsOptions.X, settingsOptions.Y, settingsOptions.COLOR,
        settingsOptions.INVERT_X_AXIS,
        settingsOptions.INVERT_Y_AXIS,
    ],
};


export const isRelevantOption = (chartType, option, index) => {
    if (
        index > 0 &&
        chartType !== chartTypesEnums.HISTOGRAM &&
        chartType !== chartTypesEnums.COMPOSED
    )
        return false;
    return relevantConfigOptions[chartType]?.includes(option);
};


export const columnsNamesEnums = Object.freeze({
    COOLING_DEMAND: 'Cooling Demand',
    DATE_TIME: 'Datetime',
    ELECTRICITY_DEMAND: 'Electricity Demand',
    HEATING_DEMAND: 'Heating Demand',
    HOT_WATER_DEMAND: 'Hot Water Demand',
    LOAD_CONDUCTION_AVG: 'Load Conduction',
    LOAD_LUMINANCE_INTERNAL_AVG: 'Load Luminance',
    LOAD_PEOPLE_INTERNAL_AVG: 'Load People',
    LOAD_SOLAR_RADIATION_AVG: 'Load Solar Radiation',
    LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG: 'Load Technical Appliances',
    LOAD_VENTILATION_AVG: 'Load Ventilation',
    LOAD_WALLSTORAGE_AVG: 'Load Wall Storage',
    OCCUPANCY_PERCENTAGE_AVG: 'Occupancy',
    TEMPERATURE_INSIDE_CALCULATED_AVG: 'Temperature Inside Calculated',
    TEMPERATURE_INSIDE_MAXIMUM_AVG: 'Temperature Inside Max',
    TEMPERATURE_INSIDE_MEASURED_AVG: 'Temperature Inside Measured',
    TEMPERATURE_INSIDE_MINIMUM_AVG: 'Temperature Inside Min',
    TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG: 'Temperature Operative Calculated',
    TEMPERATURE_OUTSIDE_AIR_AVG: 'Temperature Outside Air',
    VENTILATION_INFILTRATION_FLOW_RATE: 'Ventilation Infiltration Flow Rate',
    VENTILATION_INFILTRATION_TEMPERATURE_AVG: 'Ventilation Infiltration Temperature',
    VENTILATION_MECHANICAL_FLOW_RATE: 'Ventilation Mechanical Flow Rate',
    VENTILATION_MECHANICAL_TEMPERATURE_AVG: 'Ventilation Mechanical Temperature',
    VENTILATION_NATURAL_FLOW_RATE: 'Ventilation Natural Flow Rate',
    VENTILATION_NATURAL_TEMPERATURE_AVG: 'Ventilation Natural Temperature',

    DATAPOINT: 'Data Point',
    DAY_OF_MONTH: 'Day Of Month',
    DAY_OF_WEEK: 'Day Of Week',
    DAY_OF_YEAR: 'Day Of Year',
    HOUR_OF_DAY: 'Hour Of Day',
    HOUR_OF_YEAR: 'Hour Of Year',
    MONTH: 'Month',
    MONTH_NUMBER: 'Month Number',
    SCENARIO: 'Scenario',
    UTILITY: 'Utility',
    YEAR: 'Year',
    YEAR_MONTH: 'Year Month',
})


export const dataMapping = {
    coolingDemand_avg: columnsNamesEnums.COOLING_DEMAND,
    electricityDemand_avg: columnsNamesEnums.ELECTRICITY_DEMAND,
    heatingDemand_avg: columnsNamesEnums.HEATING_DEMAND,
    hotWaterDemand_avg: columnsNamesEnums.HOT_WATER_DEMAND,
    loadsConduction_avg: columnsNamesEnums.LOAD_CONDUCTION_AVG,
    loadsLuminanceInternal_avg: columnsNamesEnums.LOAD_LUMINANCE_INTERNAL_AVG,
    loadsPeopleInternal_avg: columnsNamesEnums.LOAD_PEOPLE_INTERNAL_AVG,
    loadsSolarRadiation_avg: columnsNamesEnums.LOAD_SOLAR_RADIATION_AVG,
    loadsTechnicalAppliancesInternal_avg: columnsNamesEnums.LOAD_TECHNICAL_APPLIANCES_INTERNAL_AVG,
    loadsVentilation_avg: columnsNamesEnums.LOAD_VENTILATION_AVG,
    loadsWallStorage_avg: columnsNamesEnums.LOAD_WALLSTORAGE_AVG,
    occupancyPercentage_avg: columnsNamesEnums.OCCUPANCY_PERCENTAGE_AVG,
    temperatureInsideCalculated_avg: columnsNamesEnums.TEMPERATURE_INSIDE_CALCULATED_AVG,
    temperatureInsideMaximum_avg: columnsNamesEnums.TEMPERATURE_INSIDE_MAXIMUM_AVG,
    temperatureInsideMeasured_avg: columnsNamesEnums.TEMPERATURE_INSIDE_MEASURED_AVG,
    temperatureInsideMinimum_avg: columnsNamesEnums.TEMPERATURE_INSIDE_MINIMUM_AVG,
    temperatureOperativeInsideCalculated_avg: columnsNamesEnums.TEMPERATURE_OPERATIVE_INSIDE_CALCULATED_AVG,
    temperatureOutsideAir_avg: columnsNamesEnums.TEMPERATURE_OUTSIDE_AIR_AVG,
    ventilationInfiltrationFlowRate_avg: columnsNamesEnums.VENTILATION_INFILTRATION_FLOW_RATE,
    ventilationInfiltrationTemperature_avg: columnsNamesEnums.VENTILATION_INFILTRATION_TEMPERATURE_AVG,
    ventilationMechanicalFlowRate_avg: columnsNamesEnums.VENTILATION_MECHANICAL_FLOW_RATE,
    ventilationMechanicalTemperature_avg: columnsNamesEnums.VENTILATION_MECHANICAL_TEMPERATURE_AVG,
    ventilationNaturalFlowRate_avg: columnsNamesEnums.VENTILATION_NATURAL_FLOW_RATE,
    ventilationNaturalTemperature_avg: columnsNamesEnums.VENTILATION_NATURAL_TEMPERATURE_AVG,

    datetime: columnsNamesEnums.DATE_TIME,
    dataPoint: columnsNamesEnums.DATAPOINT,
    dayOfMonth: columnsNamesEnums.DAY_OF_MONTH,
    dayOfWeek: columnsNamesEnums.DAY_OF_WEEK,
    dayOfYear: columnsNamesEnums.DAY_OF_YEAR,
    hourOfDay: columnsNamesEnums.HOUR_OF_DAY,
    hourOfYear: columnsNamesEnums.HOUR_OF_YEAR,
    month: columnsNamesEnums.MONTH,
    monthNumber: columnsNamesEnums.MONTH_NUMBER,
    scenario: columnsNamesEnums.SCENARIO,
    year: columnsNamesEnums.YEAR,
    yearMonth: columnsNamesEnums.YEAR_MONTH,
    utility: columnsNamesEnums.UTILITY
}

export const categoricalColumns = [
    'datetime',
    'dataPoint',
    'dayOfMonth',
    'dayOfWeek',
    'dayOfYear',
    'hourOfDay',
    'hourOfYear',
    'month',
    'monthNumber',
    'scenario',
    'year',
    'yearMonth',
    'utility'
]

export const categoricalColumnsDisplayNames = categoricalColumns.map(colName => getColumnDisplayName({ colName }))

export const staticUtilities = [
    'coolingDemand_avg',
    'electricityDemand_avg',
    'heatingDemand_avg',
    'hotWaterDemand_avg',
    'loadsConduction_avg',
    'loadsLuminanceInternal_avg',
    'loadsPeopleInternal_avg',
    'loadsSolarRadiation_avg',
    'loadsTechnicalAppliancesInternal_avg',
    'loadsVentilation_avg',
    'loadsWallStorage_avg',
    'occupancyPercentage_avg',
    'temperatureInsideCalculated_avg',
    'temperatureInsideMaximum_avg',
    'temperatureInsideMeasured_avg',
    'temperatureInsideMinimum_avg',
    'temperatureOperativeInsideCalculated_avg',
    'temperatureOutsideAir_avg',
    'ventilationInfiltrationFlowRate_avg',
    'ventilationInfiltrationTemperature_avg',
    'ventilationMechanicalFlowRate_avg',
    'ventilationMechanicalTemperature_avg',
    'ventilationNaturalFlowRate_avg',
    'ventilationNaturalTemperature_avg',
]

export const columnsUnits = Object.freeze({
    'datetime': 'Datetime',
    'dayOfMonth': '#Day',
    'dayOfWeek': '',
    'dayOfYear': '#Day',
    'hourOfDay': '#Hour',
    'hourOfYear': '#Hour',
    'month': '',
    'monthNumber': '#Month',
    'year': 'year',
    'yearMonth': '',
    'scenario': '',
    'utility': '',
})
